import { useState, useEffect } from "react";
import { Page, Navbar, BtnBlock, DialogBox, BtnSmall } from "../components";
import { Link as RouterLink } from "react-router-dom";
import * as ROUTES from "../constant/routes";

export default function ExpiringSoon() {
  const [modals, setModals] = useState({
    expandedMenu: true,
  });

  const [books, setBooks] = useState([
    {
      id: 0,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 1,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 2,
    },
    {
      id: 2,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 2,
    },
    {
      id: 3,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 4,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 5,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 2,
    },
    {
      id: 6,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 2,
    },
    {
      id: 7,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 8,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 9,
      author: "Allen Cooper",
      price: 123.4,
      desc: "The Mission Legal rights limited",
      imgSrc: null,
      validity: 1,
    },
  ]);

  return (
    <Page active="wallet">
      <Navbar />
      <h1 className="text-heading text-h1 font-semibold text-left my-8">
        Expiring soon
      </h1>
      {books.map((book) => (
        <div className="p-4 flex justify-between items-center w-full h-40 border-1 border-solid border-primary-dark-20 mb-6">
          <div className="flex">
            <div className="bg-neutral-10 w-[107px] h-32"></div>
            <div className="py-4 px-4 flex flex-col justify-between">
              <h3 className="text-heading text-left font-semibold text-h5">
                {book.desc}
              </h3>
              <p className="text-p1 text-secondary-dark font-semibold">
                {book.author}
              </p>
              <div className="flex  items-center">
                <div className="w-32 h-2 bg-primary-dark rounded-full"></div>
                <p className="text-sm text-secondary-light ml-8">in 29days</p>
              </div>
            </div>
          </div>
          <button className="border-1 border-solid border-primary-dark-20 text-p1 font-semibold w-[183px] h-12">
            Renew
          </button>
        </div>
      ))}
    </Page>
  );
}
