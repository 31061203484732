import { Routes, Route } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  CreatePassword,
  Home,
  BusinessProfile,
  Catalogue,
  BookDetails,
  ExpiringSoon,
  Departments,
  GenerateAccessIds,
  GenerateOrderIds,
  Signup,
  Profile,
  Users,
  Department,
  AssignBooks,
  OrderHistory,
  Grievances,
} from "./pages";
import SearchPage from "./pages/searchPage";
import UserOrderHistory from "./containers/user_order_history";
import Set from "./pages/set";
import SetDetail from "./pages/set_detail";
import UserOrderDetails from "./containers/user_order_details";
import * as ROUTES from "./constant/routes";
import Setting from "./pages/Settings/Setting";
import Notification from "./pages/notifications/Notification";
import { IsUserRedirect, RequireAuth } from "./helpers/routes";
import AuthProvider from "./helpers/authProvider";
import OrderDetails from "./pages/order_history/order_details";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <RequireAuth>
              {/* <Home /> */}
              <Catalogue />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.LOG_IN}
          element={
            <IsUserRedirect>
              <Login />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <IsUserRedirect>
              <Signup />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <IsUserRedirect>
              <ForgotPassword />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.CREATE_PASSWORD}
          element={
            <IsUserRedirect>
              <CreatePassword />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.DEPARTMENTS}
          element={
            <RequireAuth>
              <Departments />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SEARCH_BOOK}
          element={
            <RequireAuth>
              <SearchPage />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.DEPARTMENT}
          element={
            <RequireAuth>
              <Department />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.BUSINESS_PROFILE}
          element={
            <RequireAuth>
              <BusinessProfile />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.NOTIFICATION}
          element={
            <RequireAuth>
              <Notification />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USER_ORDER_HISTORY}
          element={
            <RequireAuth>
              <UserOrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USER_ORDER_DETAILS}
          element={
            <RequireAuth>
              <UserOrderDetails />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.ORDER_HISTORY}
          element={
            <RequireAuth>
              <OrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORDER_DETAILS}
          element={
            <RequireAuth>
              <OrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.CATALOGUE}
          element={
            <RequireAuth>
              <Catalogue />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SET}
          element={
            <RequireAuth>
              <Set />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ASSIGN_BOOKS}
          element={
            <RequireAuth>
              <AssignBooks />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ACCESS_IDS}
          element={
            <RequireAuth>
              <GenerateAccessIds />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ORDER_IDS}
          element={
            <RequireAuth>
              <GenerateOrderIds />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.EXPIRING_SOON}
          element={
            <RequireAuth>
              <ExpiringSoon />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.BOOK_DETAILS}
          element={
            <RequireAuth>
              <BookDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SET_DETAILS}
          element={
            <RequireAuth>
              <SetDetail />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USERS}
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SETTINGS}
          element={
            <RequireAuth>
              <Setting />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GRIEVANCES}
          element={
            <RequireAuth>
              <Grievances />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
