import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Page } from "../../components";
import HelpSupport from "./Help&Support";
import ResetPassword from "./ResetPassword";

export default function Setting() {
  const [settingNav, setSettingNav] = useState("helpsupport");

  function navDirection(params) {
    setSettingNav(params);
  }

  const [about, setAbout] = useState(1);

  return (
    <Page>
      <Navbar />
      <div>
        <h1 className="hidden lg:flex items-center text-mobile-h1  lg:text-h2 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
          Settings
        </h1>
      </div>
      <div className="my-3">
        <div className="flex align-center w-fit  overflow-x-scroll lg:overflow-x-visible flex-row lg:border-b-1 border-[#C5C1Ca] border-solid ">
          <ul className="flex justify-between gap-6 items-start ">
            <li
              onClick={() => navDirection("helpsupport")}
              className="text-[#726D6D] text-p inline-block py-4 px-2 mr-6"
            >
              <span
                className={`${
                  settingNav === "helpsupport"
                    ? "text-thumb border-b-1"
                    : "lg:text-heading border-b-0"
                } cursor-pointer whitespace-nowrap px-2 hover:text-thumb hover:border-b-1 pb-[2px] border-thumb border-solid`}
              >
                Help &#38; Support
              </span>
            </li>
            <li
              onClick={() => navDirection("resetpassword")}
              className="text-[#726D6D] text-p inline-block py-4 px-2 mr-6"
            >
              <span
                className={`${
                  settingNav === "resetpassword"
                    ? "text-thumb border-b-1"
                    : "lg:text-heading border-b-0"
                } cursor-pointer whitespace-nowrap px-2 hover:text-thumb hover:border-b-1 pb-[2px] border-thumb border-solid`}
              >
                Reset Password
              </span>
            </li>
          </ul>
        </div>
        <div>
          {settingNav === "helpsupport" ? (
            <HelpSupport />
          ) : settingNav === "resetpassword" ? (
            <ResetPassword />
          ) : null}
        </div>
      </div>
    </Page>
  );
}
