import * as ROUTES from "../constant/routes";
import { Link as RouterLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import ReactTooltip from "react-tooltip";
import Elogo2 from "../assets/images/eprakashak2.jpg";

export default function Sidebar({ active }) {
  return (
    <>
      <div className="hidden lg:block bg-primary-dark w-sidebar min-h-screen">
        <div className="mt-6 w-full h-24 flex justify-center items-end">
          <RouterLink to={ROUTES.CATALOGUE}>
          <img 
            //  src={logo}
              src={Elogo2}
              className="h-fit w-[80px] mb-4"
              alt="plb's logo" 
              />
          </RouterLink>
        </div>
        <div className="w-full h-sidebar flex justify-center items-center ">
           <div className="w-full flex justify-center items-center flex-col">
            <ReactTooltip
              place="right"
              backgroundColor="#737272a"
              type="success"
              effect="solid"
              offset={{ bottom: 5 }}
              delayShow="200"
            />
            {/* <button className="mb-10" data-tip="Metrics">
              <svg
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.4493 6.67007C20.6793 5.09672 18.4242 4.02526 15.9691 3.59117C13.514 3.15709 10.9693 3.37987 8.65666 4.23136C6.34402 5.08285 4.36739 6.5248 2.9767 8.37486C1.58601 10.2249 0.843742 12.4 0.84375 14.625V18.75H8.4375V17.25H2.53125V14.625C2.53125 9.2489 7.45184 4.87504 13.5 4.87504C19.5482 4.87504 24.4687 9.2489 24.4687 14.625V17.25H18.5625V18.75H26.1562V14.625C26.1608 13.1471 25.8355 11.683 25.1992 10.3175C24.5629 8.95204 23.6283 7.71231 22.4493 6.67007Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M4.21875 12.375H5.90625V13.875H4.21875V12.375Z"
                  fill="#BB7AAF"
                />
                <path d="M12.6562 6H14.3438V7.5H12.6562V6Z" fill="#BB7AAF" />
                <path
                  d="M7.17188 7.875H8.85938V9.375H7.17188V7.875Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M21.0938 12.375H22.7812V13.875H21.0938V12.375Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M15.6738 15.708L19.323 8.94991L17.8016 8.30078L14.1533 15.0571C13.3458 14.9141 12.5072 15.0378 11.795 15.4051C11.0827 15.7724 10.5457 16.358 10.2845 17.0521C10.0233 17.7463 10.056 18.5012 10.3764 19.1755C10.6967 19.8498 11.2828 20.397 12.0247 20.7146C12.7665 21.0322 13.6132 21.0983 14.4061 20.9006C15.1989 20.7028 15.8833 20.2548 16.331 19.6406C16.7787 19.0263 16.959 18.2879 16.838 17.5639C16.717 16.84 16.3031 16.1801 15.6738 15.708ZM13.5 19.5002C13.1662 19.5002 12.84 19.4122 12.5624 19.2474C12.2849 19.0826 12.0686 18.8483 11.9409 18.5742C11.8132 18.3001 11.7798 17.9985 11.8449 17.7076C11.91 17.4166 12.0707 17.1493 12.3067 16.9395C12.5427 16.7298 12.8434 16.5869 13.1708 16.529C13.4981 16.4711 13.8374 16.5009 14.1458 16.6144C14.4541 16.7279 14.7177 16.9202 14.9031 17.1668C15.0885 17.4135 15.1875 17.7035 15.1875 18.0002C15.187 18.3979 15.009 18.7792 14.6927 19.0604C14.3763 19.3416 13.9474 19.4998 13.5 19.5002Z"
                  fill="#BB7AAF"
                />
              </svg>
            </button> */}
            <RouterLink
              to={ROUTES.CATALOGUE}
              className="mb-10"
              data-tip="Library- Books"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.000488281 1.33344C0.000488281 0.979787 0.140956 0.640622 0.39099 0.390555C0.641024 0.140487 0.980143 0 1.33374 0H5.33351C5.68711 0 6.02623 0.140487 6.27626 0.390555C6.5263 0.640622 6.66677 0.979787 6.66677 1.33344V5.33375C6.66677 5.6874 6.5263 6.02656 6.27626 6.27663C6.02623 6.5267 5.68711 6.66718 5.33351 6.66718H1.33374C0.980143 6.66718 0.641024 6.5267 0.39099 6.27663C0.140956 6.02656 0.000488281 5.6874 0.000488281 5.33375V1.33344Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M0 10.0024C0 9.64873 0.140468 9.30957 0.390501 9.0595C0.640535 8.80943 0.979654 8.66895 1.33326 8.66895H5.33302C5.68662 8.66895 6.02574 8.80943 6.27578 9.0595C6.52581 9.30957 6.66628 9.64873 6.66628 10.0024V14.0027C6.66628 14.3563 6.52581 14.6955 6.27578 14.9456C6.02574 15.1956 5.68662 15.3361 5.33302 15.3361H1.33326C0.979654 15.3361 0.640535 15.1956 0.390501 14.9456C0.140468 14.6955 0 14.3563 0 14.0027V10.0024Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M0 18.6655C0 18.3118 0.140468 17.9727 0.390501 17.7226C0.640535 17.4725 0.979654 17.332 1.33326 17.332H5.33302C5.68662 17.332 6.02574 17.4725 6.27578 17.7226C6.52581 17.9727 6.66628 18.3118 6.66628 18.6655V22.6658C6.66628 23.0194 6.52581 23.3586 6.27578 23.6087C6.02574 23.8587 5.68662 23.9992 5.33302 23.9992H1.33326C0.979654 23.9992 0.640535 23.8587 0.390501 23.6087C0.140468 23.3586 0 23.0194 0 22.6658V18.6655Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M8.66797 1.33344C8.66797 0.979787 8.80844 0.640622 9.05847 0.390555C9.3085 0.140487 9.64762 0 10.0012 0H14.001C14.3546 0 14.6937 0.140487 14.9437 0.390555C15.1938 0.640622 15.3342 0.979787 15.3342 1.33344V5.33375C15.3342 5.6874 15.1938 6.02656 14.9437 6.27663C14.6937 6.5267 14.3546 6.66718 14.001 6.66718H10.0012C9.64762 6.66718 9.3085 6.5267 9.05847 6.27663C8.80844 6.02656 8.66797 5.6874 8.66797 5.33375V1.33344Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M8.66797 10.0024C8.66797 9.64873 8.80844 9.30957 9.05847 9.0595C9.3085 8.80943 9.64762 8.66895 10.0012 8.66895H14.001C14.3546 8.66895 14.6937 8.80943 14.9437 9.0595C15.1938 9.30957 15.3342 9.64873 15.3342 10.0024V14.0027C15.3342 14.3563 15.1938 14.6955 14.9437 14.9456C14.6937 15.1956 14.3546 15.3361 14.001 15.3361H10.0012C9.64762 15.3361 9.3085 15.1956 9.05847 14.9456C8.80844 14.6955 8.66797 14.3563 8.66797 14.0027V10.0024Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M8.66797 18.6664C8.66797 18.3128 8.80844 17.9736 9.05847 17.7236C9.3085 17.4735 9.64762 17.333 10.0012 17.333H14.001C14.3546 17.333 14.6937 17.4735 14.9437 17.7236C15.1938 17.9736 15.3342 18.3128 15.3342 18.6664V22.6668C15.3342 23.0204 15.1938 23.3596 14.9437 23.6096C14.6937 23.8597 14.3546 24.0002 14.001 24.0002H10.0012C9.64762 24.0002 9.3085 23.8597 9.05847 23.6096C8.80844 23.3596 8.66797 23.0204 8.66797 22.6668V18.6664Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M17.3335 1.33344C17.3335 0.979787 17.474 0.640622 17.724 0.390555C17.974 0.140487 18.3132 0 18.6668 0H22.6665C23.0201 0 23.3592 0.140487 23.6093 0.390555C23.8593 0.640622 23.9998 0.979787 23.9998 1.33344V5.33375C23.9998 5.6874 23.8593 6.02656 23.6093 6.27663C23.3592 6.5267 23.0201 6.66718 22.6665 6.66718H18.6668C18.3132 6.66718 17.974 6.5267 17.724 6.27663C17.474 6.02656 17.3335 5.6874 17.3335 5.33375V1.33344Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M17.333 10.0024C17.333 9.64873 17.4735 9.30957 17.7235 9.0595C17.9735 8.80943 18.3127 8.66895 18.6663 8.66895H22.666C23.0196 8.66895 23.3588 8.80943 23.6088 9.0595C23.8588 9.30957 23.9993 9.64873 23.9993 10.0024V14.0027C23.9993 14.3563 23.8588 14.6955 23.6088 14.9456C23.3588 15.1956 23.0196 15.3361 22.666 15.3361H18.6663C18.3127 15.3361 17.9735 15.1956 17.7235 14.9456C17.4735 14.6955 17.333 14.3563 17.333 14.0027V10.0024Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M17.333 18.6664C17.333 18.3128 17.4735 17.9736 17.7235 17.7236C17.9735 17.4735 18.3127 17.333 18.6663 17.333H22.666C23.0196 17.333 23.3588 17.4735 23.6088 17.7236C23.8588 17.9736 23.9993 18.3128 23.9993 18.6664V22.6668C23.9993 23.0204 23.8588 23.3596 23.6088 23.6096C23.3588 23.8597 23.0196 24.0002 22.666 24.0002H18.6663C18.3127 24.0002 17.9735 23.8597 17.7235 23.6096C17.4735 23.3596 17.333 23.0204 17.333 22.6668V18.6664Z"
                  fill="#BB7AAF"
                />
              </svg>
            </RouterLink>
            <RouterLink to={ROUTES.SET} className="mb-10" data-tip="Library- Sets">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_7974_46666)">
                  <path
                    d="M-0.36261 17.8487H-1.55407L0.0624824 18.7817L10.3333 24.7099L10.5833 24.8541L10.8332 24.7098L25.2499 16.3869L25.4999 16.2426V15.9539V13.5018V12.9968V12.7081V12.6358V10.5436V10.2549V10.2539H24.9999H24.9993L24.9062 10.0926L25.2499 9.89422L25.4999 9.74988V9.4612V7.00599V6.50245L25.6562 6.41221L25.4999 6.32198V6.13992L25.3423 6.23096L24.9062 5.97921L20.3228 3.33338L20.0729 3.18911L20.0723 3.18945L19.6343 2.93654L17.5093 1.70945L17.2593 1.56505L17.2587 1.56536L16.8229 1.31364L15.05 0.289682L14.8 0.14528L14.5499 0.289644L0.477011 8.41464L-0.253143 8.8362H-1.66873L-0.375104 9.58282C-0.451013 10.0239 -0.433272 10.4761 -0.388142 10.8573C-0.328053 11.3649 -0.212811 11.7978 -0.15136 12.0053L-0.142078 12.0366L-0.285342 12.3742C-0.539358 12.9728 -0.528412 13.6222 -0.45368 14.134C-0.377918 14.6528 -0.228424 15.0841 -0.153381 15.2798L-0.147723 15.2946L-0.285598 15.6206C-0.538765 16.2194 -0.52789 16.8687 -0.453314 17.3807C-0.429036 17.5473 -0.39717 17.705 -0.36261 17.8487Z"
                    fill="#BB7AAF"
                    stroke="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7974_46666">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </RouterLink>
            <RouterLink
              to={ROUTES.USERS}
              className="mb-10"
              data-tip="User"
            >
              <svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.7286 9.51008C19.5829 10.3788 18.1159 10.9013 16.5189 10.9013C15.9661 10.9013 15.4288 10.8386 14.9152 10.7209C15.8337 12.0075 16.3689 13.5454 16.3689 15.1963V17.6211C16.3689 18.528 16.0089 19.3586 15.4145 20H21.4142C22.84 20 24 18.9329 24 17.6211V15.1963C24 12.8364 22.702 10.7503 20.7286 9.51008Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M7.48136 9.6076C10.3607 9.6076 12.7032 7.45268 12.7032 4.80388C12.7032 2.15492 10.3607 0 7.48136 0C4.60202 0 2.25955 2.15492 2.25955 4.80388C2.25955 7.45268 4.60202 9.6076 7.48136 9.6076Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M16.5186 9.6076C19.3979 9.6076 21.7406 7.45268 21.7406 4.80388C21.7406 2.15492 19.3979 0 16.5186 0C15.1257 0 13.8586 0.504325 12.9213 1.32482C13.6697 2.31225 14.1093 3.51157 14.1093 4.80388C14.1093 6.09603 13.6697 7.29536 12.9213 8.28278C13.8586 9.10328 15.1257 9.6076 16.5186 9.6076Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M11.6911 9.51008C10.5456 10.3788 9.07856 10.9013 7.48133 10.9013C5.8841 10.9013 4.41706 10.3789 3.27137 9.51008C1.29804 10.7503 0 12.8364 0 15.1963V17.6211C0 18.9329 1.15997 20 2.586 20H12.3765C13.8025 20 14.9625 18.9329 14.9625 17.6211V15.1963C14.9625 12.8364 13.6646 10.7503 11.6911 9.51008Z"
                  fill="#BB7AAF"
                />
              </svg>
            </RouterLink>
            <RouterLink
              to={ROUTES.DEPARTMENTS}
              className="mb-10"
              data-tip="Departments"
            >
              <svg
                width="30"
                height="29"
                viewBox="0 0 30 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0018 12.873C17.1926 12.873 18.9685 11.1779 18.9685 9.08689C18.9685 6.99588 17.1926 5.30078 15.0018 5.30078C12.8111 5.30078 11.0352 6.99588 11.0352 9.08689C11.0352 11.1779 12.8111 12.873 15.0018 12.873Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M8.98536 9.46549H9.38536V9.1191C9.38779 8.11183 9.68176 7.12532 10.234 6.27115C10.7863 5.41699 11.5748 4.72926 12.5104 4.28577C12.2334 3.66916 11.7888 3.13659 11.2239 2.74461C10.659 2.35262 9.99473 2.11587 9.30178 2.0595C8.60883 2.00312 7.91302 2.12923 7.28825 2.42442C6.66349 2.71962 6.13309 3.17288 5.75341 3.73606C5.37373 4.29924 5.15893 4.95132 5.13183 5.62303C5.10473 6.29474 5.26635 6.96101 5.5995 7.55106C5.93266 8.14112 6.42493 8.63293 7.02401 8.97426C7.6231 9.31559 8.30664 9.49369 9.00203 9.48965L8.98536 9.46549Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M20.6333 9.087V9.43339H21.0333C21.7195 9.42673 22.391 9.2414 22.9774 8.89689C23.5637 8.55237 24.0432 8.06143 24.3652 7.47573C24.6873 6.89004 24.84 6.23124 24.8074 5.5687C24.7748 4.90616 24.558 4.26437 24.1798 3.7109C23.8017 3.15742 23.2762 2.71272 22.6586 2.42361C22.0411 2.13449 21.3543 2.01166 20.6706 2.06804C19.9869 2.12442 19.3316 2.35792 18.7737 2.74397C18.2157 3.13001 17.7757 3.65433 17.5 4.26173C18.4359 4.70349 19.2252 5.38947 19.7789 6.24216C20.3326 7.09485 20.6286 8.08025 20.6333 9.087Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M18.5781 13.2516C20.235 13.5687 21.8371 14.1111 23.3365 14.8628C23.5477 14.9749 23.739 15.1191 23.9031 15.2897H28.3365V12.5347C28.3373 12.429 28.3082 12.3252 28.2524 12.2343C28.1966 12.1434 28.1161 12.069 28.0198 12.0191C25.8599 10.9271 23.4573 10.3602 21.0198 10.3677H20.4698C20.181 11.5025 19.5168 12.5152 18.5781 13.2516Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M5.44299 16.8522C5.44146 16.4465 5.55481 16.0482 5.77074 15.7003C5.98667 15.3524 6.29695 15.0682 6.66799 14.8786C8.16739 14.127 9.76943 13.5845 11.4263 13.2675C10.4922 12.5373 9.82842 11.5338 9.53465 10.4078H8.98465C6.54718 10.4003 4.14453 10.9671 1.98465 12.0592C1.88829 12.1091 1.80786 12.1835 1.75206 12.2743C1.69625 12.3652 1.66718 12.4691 1.66799 12.5747V17.7222H5.44299V16.8522Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M17.8828 21.5H22.8495V22.6278H17.8828V21.5Z"
                  fill="#BB7AAF"
                />
                <path
                  d="M27.3427 17.1262H21.6177V16.3207C21.6177 16.107 21.5299 15.9021 21.3736 15.7511C21.2174 15.6 21.0054 15.5151 20.7844 15.5151C20.5634 15.5151 20.3514 15.6 20.1951 15.7511C20.0389 15.9021 19.9511 16.107 19.9511 16.3207V17.1262H18.3344V14.8465C17.238 14.624 16.1213 14.5079 15.0011 14.5001C12.3709 14.4895 9.77824 15.1036 7.45106 16.2885C7.3474 16.3404 7.26063 16.4191 7.20033 16.5158C7.14004 16.6125 7.10856 16.7234 7.10939 16.8362V21.3554H13.0344V26.2612C13.0344 26.4749 13.1222 26.6798 13.2785 26.8309C13.4347 26.9819 13.6467 27.0668 13.8677 27.0668H27.3427C27.5637 27.0668 27.7757 26.9819 27.932 26.8309C28.0883 26.6798 28.1761 26.4749 28.1761 26.2612V17.9318C28.1761 17.7182 28.0883 17.5133 27.932 17.3622C27.7757 17.2111 27.5637 17.1262 27.3427 17.1262ZM26.5094 25.4718H14.7011V18.7374H19.9511V19.4704C19.9511 19.6841 20.0389 19.889 20.1951 20.04C20.3514 20.1911 20.5634 20.276 20.7844 20.276C21.0054 20.276 21.2174 20.1911 21.3736 20.04C21.5299 19.889 21.6177 19.6841 21.6177 19.4704V18.7374H26.5094V25.4718Z"
                  fill="#BB7AAF"
                />
              </svg>
            </RouterLink>
            <RouterLink
              to={ROUTES.ORDER_HISTORY}
              className="mb-10"
              data-tip="Order History"
            >
              <svg
                width="25"
                height="29"
                viewBox="0 0 25 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8667 4.2002H3.13333C2.50741 4.2002 2 4.69268 2 5.3002V22.9002C2 23.5077 2.50741 24.0002 3.13333 24.0002H17.8667C18.4926 24.0002 19 23.5077 19 22.9002V5.3002C19 4.69268 18.4926 4.2002 17.8667 4.2002Z"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.10156 2V5.3"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8984 2V5.3"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.96875 10.25H15.0354"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.96875 14.6504H12.7687"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.96875 19.0498H10.5021"
                  stroke="#BB7AAF"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <ellipse cx="17.5" cy="22" rx="7.5" ry="7" fill="#702963" />
                <path
                  d="M17.8524 18.7324H17.1478C17.0833 18.7324 17.0306 18.7816 17.0306 18.8418V22.6057C17.0306 22.6412 17.0482 22.674 17.0789 22.6945L19.5003 24.3447C19.5531 24.3803 19.6263 24.3707 19.6644 24.3215L20.0833 23.7883C20.1229 23.7377 20.1112 23.6693 20.0584 23.6352L17.9696 22.2256V18.8418C17.9696 18.7816 17.9168 18.7324 17.8524 18.7324ZM21.0721 19.7605L23.369 20.2842C23.4422 20.3006 23.514 20.2486 23.514 20.1789L23.5257 17.9709C23.5257 17.8793 23.4129 17.8273 23.3368 17.8848L21.0282 19.5678C21.0107 19.5804 20.9974 19.5973 20.9898 19.6166C20.9822 19.636 20.9806 19.6569 20.9852 19.677C20.9898 19.6972 21.0004 19.7157 21.0158 19.7305C21.0312 19.7452 21.0507 19.7557 21.0721 19.7605ZM23.5286 23.8772L22.6981 23.6105C22.6691 23.6013 22.6374 23.603 22.6098 23.6152C22.5821 23.6275 22.5607 23.6494 22.5501 23.6762C22.5223 23.7459 22.493 23.8143 22.4622 23.8826C22.2015 24.4582 21.828 24.9764 21.3504 25.4207C20.8782 25.8628 20.3185 26.2152 19.7025 26.4584C19.0643 26.7102 18.3783 26.8395 17.6854 26.8385C16.9852 26.8385 16.307 26.7113 15.6683 26.4584C15.0522 26.2152 14.4926 25.8628 14.0203 25.4207C13.5443 24.9764 13.1707 24.4582 12.9085 23.8826C12.6402 23.2866 12.5027 22.6465 12.5042 22C12.5042 21.3465 12.6405 20.7121 12.9115 20.116C13.1722 19.5404 13.5457 19.0223 14.0233 18.5779C14.4955 18.1358 15.0552 17.7834 15.6712 17.5402C16.307 17.2873 16.9867 17.1602 17.6868 17.1602C18.387 17.1602 19.0653 17.2873 19.7039 17.5402C20.32 17.7834 20.8797 18.1358 21.3519 18.5779C21.5013 18.7188 21.6419 18.865 21.7708 19.0195L22.6468 18.3797C21.494 17.0043 19.6995 16.1197 17.6839 16.1211C14.1742 16.1225 11.3558 18.783 11.391 22.0602C11.4261 25.2799 14.2313 27.8789 17.6868 27.8789C20.4041 27.8789 22.7186 26.2711 23.6004 24.0193C23.6224 23.9619 23.5902 23.8977 23.5286 23.8772Z"
                  fill="#BB7AAF"
                />
              </svg>
            </RouterLink>
            <RouterLink
              to={ROUTES.GRIEVANCES}
              className="mb-10"
              data-tip="Grievances"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_7784_36140)">
                  <path
                    d="M12.5 0C9.18479 0 6.00537 1.31696 3.66116 3.66116C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66116 21.3388C6.00537 23.683 9.18479 25 12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66116C18.9946 1.31696 15.8152 0 12.5 0V0ZM5.11562 7.38437H17.049C17.6489 7.39311 18.2213 7.63708 18.6431 8.06375C19.0649 8.49042 19.3023 9.06566 19.3042 9.66562V15.7729C19.2948 16.3715 19.0529 16.943 18.6296 17.3664C18.2064 17.7898 17.6351 18.032 17.0365 18.0417H8.375C7.15208 18.0417 6.25 17.0104 6.25 15.7729V10.9406L4.69062 8.23437C4.36771 7.79687 4.63854 7.38437 5.11562 7.38437V7.38437ZM8.67292 9.66562C8.31146 9.66562 7.96458 9.88437 7.96458 10.2323C7.96458 10.5802 8.31146 10.799 8.67292 10.799H16.9073C17.2677 10.799 17.6156 10.5802 17.6156 10.2323C17.6156 9.88437 17.2677 9.66458 16.9073 9.66458L8.67292 9.66562ZM8.67292 11.9333C8.31146 11.9333 7.96458 12.1521 7.96458 12.5C7.96458 12.8479 8.31146 13.0667 8.67292 13.0667H16.9073C17.2677 13.0667 17.6156 12.8479 17.6156 12.5C17.6156 12.1521 17.2677 11.9333 16.9073 11.9333H8.67292ZM8.65937 14.2146C8.29896 14.2146 7.95104 14.4333 7.95104 14.7812C7.95104 15.1292 8.29896 15.3479 8.65937 15.3479H14.626C14.9875 15.3479 15.3344 15.1292 15.3344 14.7812C15.3344 14.4333 14.9875 14.2135 14.626 14.2135L8.65937 14.2146Z"
                    fill="#BB7AAF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7784_36140">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </RouterLink>
          </div>
        </div>
      </div> 
      <div className="hidden w-8 lg:hidden">
        <button className="outline-0 border-0 p-2 absolute top-7 left-6 ">
          <svg
            width="32"
            height="31"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2408 13.95H5.76078C4.87758 13.95 4.80078 14.6428 4.80078 15.5C4.80078 16.3572 4.87758 17.05 5.76078 17.05H26.2408C27.124 17.05 27.2008 16.3572 27.2008 15.5C27.2008 14.6428 27.124 13.95 26.2408 13.95ZM26.2408 20.15H5.76078C4.87758 20.15 4.80078 20.8428 4.80078 21.7C4.80078 22.5571 4.87758 23.25 5.76078 23.25H26.2408C27.124 23.25 27.2008 22.5571 27.2008 21.7C27.2008 20.8428 27.124 20.15 26.2408 20.15ZM5.76078 10.85H26.2408C27.124 10.85 27.2008 10.1571 27.2008 9.3C27.2008 8.44285 27.124 7.75 26.2408 7.75H5.76078C4.87758 7.75 4.80078 8.44285 4.80078 9.3C4.80078 10.1571 4.87758 10.85 5.76078 10.85Z"
              fill="#545454"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
