import React from "react";

export default function btnSmall({ notification, children, ...restProps}) {
  return (
    <button
      {...restProps}
      // onClick={() =>  console.log(notification())}
      className={`flex items-center justify-center text-center bg-primary-dark hover:border-2 hover:border-primary-dark hover:border-solid w-150 h-14 text-primary-white`}
    >
      {children}
    </button>
  );
}
