import { useState } from "react";
import { createPortal } from "react-dom";
import Input from "../../components/form/input";
import { BtnBlock, PopUp, DialogBox, BtnBlockDisabled } from "../../components";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as ROUTES from "../../constant/routes";
import login1 from "../../assets/images/login/login1.png";
import { handleResetPassword } from "../../assets/Js/script";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function CreatePassword() {
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    password: "",
    current_password: "",
  });
  const Params = useParams();
  const notify = (message) => toast(message);
  const navigate = useNavigate();

  const [slideShowButtons, setSlideShowButtons] = useState([
    { id: 0, active: false },
    { id: 1, active: true },
    { id: 2, active: false },
  ]);

  const disabled = formData.password === "" && formData.current_password === "";

  function handleClose(e) {
    setShowPopup(false);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let tmpErrors = {}
    if (formData.password.length < 8 || (!/[A-Za-z]/.test(formData.password)) || !/\d/.test(formData.password) || !/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(formData.password)) {
      tmpErrors.password = true

    } else {
      tmpErrors.password = false
    }
    setErrors(prev => ({...tmpErrors }))
   
    if (tmpErrors.password) {
      return
    } 
    const { response } = await handleResetPassword(
      formData.password,
      formData.current_password,
      Params.id
    );
    if (response.status === "success") {
      setShowPopup(true);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  return (
    <div className="min-w-screen flex min-h-screen box-border">
      <div className=" mt-12 lg:mt-0 w-full py-4 lg:py-0 lg:w-3/5 flex justify-center items-start lg:items-center lg:border-r-8  border-r-secondary-yellow border-solid">
        <form
          onSubmit={handleSubmit}
          className="flex justify-between flex-col w-[380px] lg:w-354 h-100 "
        >
          <h1 className="flex items-center text-[21px] lg:text-h1 text-tertiary-dark font-bold ">
            <button className=" lg:hidden mr-2">
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5491 2.54755L9.91739 0.925049L0.851562 10L9.92656 19.075L11.5491 17.4525L4.09656 10L11.5491 2.54755Z"
                  fill="#702963"
                />
              </svg>
            </button>
            Create Password
          </h1>
          <div className="relative">
            <Input
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              name="password"
              onChange={handleChange}
              required
            />
            <button
              className="absolute  right-4  focus:outline-none top-[60px]"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword((prevState) => !prevState);
              }}
            >
              {showPassword ? (
                <svg
                  width="27"
                  height="12"
                  viewBox="0 0 27 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-tip="Show Password"
                >
                  <path
                    d="M17.5465 5.99788C17.5465 7.58673 15.7315 8.87475 13.4927 8.87475C11.2538 8.87475 9.43889 7.58673 9.43889 5.99788C9.43889 4.40901 11.2538 3.12101 13.4927 3.12101C15.7315 3.121 17.5465 4.40903 17.5465 5.99788ZM13.5 0.0078125C11.1819 0.0154292 8.77919 0.433446 6.50466 1.23376C4.81586 1.85246 3.17003 2.72536 1.74137 3.80278C1.03968 4.35276 0.144698 5.14913 0 5.99881C0.0171 6.73483 1.08292 7.6433 1.74137 8.19486C3.08108 9.22996 4.68405 10.0785 6.50466 10.7645C8.62576 11.527 10.9735 11.966 13.5 11.9905C15.8203 11.9827 18.2225 11.5599 20.4945 10.7645C22.1833 10.1458 23.83 9.27228 25.2586 8.19486C25.9603 7.64488 26.8553 6.8485 27 5.99881C26.9829 5.2628 25.9171 4.3543 25.2586 3.80275C23.9189 2.76765 22.3151 1.91976 20.4945 1.23373C18.3745 0.471796 16.0209 0.0357958 13.5 0.0078125ZM13.4983 1.49528C17.011 1.49528 19.8585 3.51185 19.8585 5.99946C19.8585 8.48706 17.011 10.5036 13.4983 10.5036C9.98561 10.5036 7.1381 8.48705 7.1381 5.99946C7.1381 3.51185 9.98561 1.49528 13.4983 1.49528Z"
                    fill="#ADAAAA"
                  />
                </svg>
              ) : (
                <svg
                  width="27"
                  height="18"
                  viewBox="0 0 27 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-tip="Hide Password"

                >
                  <path
                    d="M15.0689 3.55894C14.5538 3.51767 14.0305 3.49402 13.5 3.48814C11.1819 3.49575 8.77898 3.91342 6.50446 4.71374C4.81565 5.33244 3.17056 6.20589 1.74188 7.28331C1.04022 7.83331 0.144697 8.62968 0 9.47935C0.0171 10.2154 1.08344 11.1238 1.74188 11.6754C3.0816 12.7105 4.68385 13.559 6.50446 14.245C6.56633 14.2672 6.62843 14.2892 6.69067 14.3109L5.00155 16.4959L7.29666 17.5007L19.7035 1.50013L17.4943 0.5L15.0689 3.55894V3.55894ZM20.3077 4.65026L18.6218 6.81454C19.3974 7.56091 19.8578 8.48131 19.8578 9.47935C19.8578 11.967 17.011 13.9838 13.4983 13.9838C13.3465 13.9838 13.1993 13.9716 13.0501 13.9642L11.9344 15.3949C12.449 15.4357 12.9692 15.4654 13.5 15.4706C15.8202 15.4629 18.2218 15.0404 20.4939 14.245C22.1827 13.6263 23.8294 12.7528 25.2581 11.6754C25.9598 11.1254 26.8553 10.329 27 9.47935C26.9829 8.74333 25.9165 7.83486 25.2581 7.28329C23.9184 6.24819 22.3145 5.39974 20.4939 4.71371C20.4324 4.69164 20.3695 4.67179 20.3077 4.65026V4.65026ZM13.4984 4.97497C13.6524 4.97497 13.8052 4.97954 13.9565 4.98717L12.6497 6.66319C10.8156 6.93863 9.43945 8.09453 9.43945 9.47815C9.43945 9.82571 9.52587 10.1585 9.68499 10.4669C9.68517 10.4672 9.68481 10.4678 9.68499 10.4681L8.37486 12.149C7.59746 11.402 7.13889 10.4785 7.13889 9.47933C7.13891 6.99174 9.98568 4.97496 13.4984 4.97497V4.97497ZM17.3002 8.50889L14.3553 12.2882C16.1795 12.0076 17.5457 10.8569 17.5457 9.47815C17.5457 9.13691 17.4538 8.81249 17.3002 8.50889V8.50889Z"
                    fill="#ADAAAA"
                  />
                </svg>
              )}
            </button>
          </div>
          {errors.password && (
            <p
              className={` mb-4 md:mb-6 mt-[3px] md:mt-[3px] text-[13px] text-[red] `}
            >
              {errors.password && "Password should have "}
              <br />
              {errors.password && "- minimum eight characters,"}
              <br />
              {errors.password && "- one letter,"}
              <br />
              {errors.password &&
                "- one number and- one special character."}
              <br />
            </p>
          )}
          <div className="relative">
            <Input
              label="Confirm Password"
              type={showPasswordTwo ? "text" : "password"}
              name="current_password"
              placeholder="Confirm password"
              onChange={handleChange}
              required
            />
            <button
              className="absolute  right-4  focus:outline-none top-[60px]"
              onClick={(e) => {
                e.preventDefault();
                setShowPasswordTwo((prevState) => !prevState);
              }}
            >
              {showPasswordTwo ? (
                <svg
                  width="27"
                  height="12"
                  viewBox="0 0 27 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-tip="Show Password"
                >
                  <path
                    d="M17.5465 5.99788C17.5465 7.58673 15.7315 8.87475 13.4927 8.87475C11.2538 8.87475 9.43889 7.58673 9.43889 5.99788C9.43889 4.40901 11.2538 3.12101 13.4927 3.12101C15.7315 3.121 17.5465 4.40903 17.5465 5.99788ZM13.5 0.0078125C11.1819 0.0154292 8.77919 0.433446 6.50466 1.23376C4.81586 1.85246 3.17003 2.72536 1.74137 3.80278C1.03968 4.35276 0.144698 5.14913 0 5.99881C0.0171 6.73483 1.08292 7.6433 1.74137 8.19486C3.08108 9.22996 4.68405 10.0785 6.50466 10.7645C8.62576 11.527 10.9735 11.966 13.5 11.9905C15.8203 11.9827 18.2225 11.5599 20.4945 10.7645C22.1833 10.1458 23.83 9.27228 25.2586 8.19486C25.9603 7.64488 26.8553 6.8485 27 5.99881C26.9829 5.2628 25.9171 4.3543 25.2586 3.80275C23.9189 2.76765 22.3151 1.91976 20.4945 1.23373C18.3745 0.471796 16.0209 0.0357958 13.5 0.0078125ZM13.4983 1.49528C17.011 1.49528 19.8585 3.51185 19.8585 5.99946C19.8585 8.48706 17.011 10.5036 13.4983 10.5036C9.98561 10.5036 7.1381 8.48705 7.1381 5.99946C7.1381 3.51185 9.98561 1.49528 13.4983 1.49528Z"
                    fill="#ADAAAA"
                  />
                </svg>
              ) : (
                <svg
                  width="27"
                  height="18"
                  viewBox="0 0 27 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-tip="Hide Password"

                >
                  <path
                    d="M15.0689 3.55894C14.5538 3.51767 14.0305 3.49402 13.5 3.48814C11.1819 3.49575 8.77898 3.91342 6.50446 4.71374C4.81565 5.33244 3.17056 6.20589 1.74188 7.28331C1.04022 7.83331 0.144697 8.62968 0 9.47935C0.0171 10.2154 1.08344 11.1238 1.74188 11.6754C3.0816 12.7105 4.68385 13.559 6.50446 14.245C6.56633 14.2672 6.62843 14.2892 6.69067 14.3109L5.00155 16.4959L7.29666 17.5007L19.7035 1.50013L17.4943 0.5L15.0689 3.55894V3.55894ZM20.3077 4.65026L18.6218 6.81454C19.3974 7.56091 19.8578 8.48131 19.8578 9.47935C19.8578 11.967 17.011 13.9838 13.4983 13.9838C13.3465 13.9838 13.1993 13.9716 13.0501 13.9642L11.9344 15.3949C12.449 15.4357 12.9692 15.4654 13.5 15.4706C15.8202 15.4629 18.2218 15.0404 20.4939 14.245C22.1827 13.6263 23.8294 12.7528 25.2581 11.6754C25.9598 11.1254 26.8553 10.329 27 9.47935C26.9829 8.74333 25.9165 7.83486 25.2581 7.28329C23.9184 6.24819 22.3145 5.39974 20.4939 4.71371C20.4324 4.69164 20.3695 4.67179 20.3077 4.65026V4.65026ZM13.4984 4.97497C13.6524 4.97497 13.8052 4.97954 13.9565 4.98717L12.6497 6.66319C10.8156 6.93863 9.43945 8.09453 9.43945 9.47815C9.43945 9.82571 9.52587 10.1585 9.68499 10.4669C9.68517 10.4672 9.68481 10.4678 9.68499 10.4681L8.37486 12.149C7.59746 11.402 7.13889 10.4785 7.13889 9.47933C7.13891 6.99174 9.98568 4.97496 13.4984 4.97497V4.97497ZM17.3002 8.50889L14.3553 12.2882C16.1795 12.0076 17.5457 10.8569 17.5457 9.47815C17.5457 9.13691 17.4538 8.81249 17.3002 8.50889V8.50889Z"
                    fill="#ADAAAA"
                  />
                </svg>
              )}
            </button>
          </div>
          {formData.password !== formData.current_password && <p className="text-[#eb3838]"> Confirm Password is not equal to passsword !</p>}
          <br className="my-4" />
          {disabled ? (
            <BtnBlockDisabled disabled={disabled}>
              Create Password
            </BtnBlockDisabled>
          ) : (
            <BtnBlock>Create Password</BtnBlock>
          )}
        </form>
      </div>
      <div className="hidden w-2/5 h-screen lg:flex justify-center items-center relative bg-primary-dark">
        <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
          <div className="">
            <img
              className="h-full block"
              src={login1}
              alt="login slideshow 1"
            />
          </div>
          <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
            Lorem ipsum dolor sit amet,
            <br /> consectetur adi
          </p>
          <div className="w-24 h-4 mt-10 flex justify-evenly mx-auto items-center">
            {slideShowButtons.map((item) =>
              item.active ? (
                <button
                  key={item.id}
                  className="w-8 h-2 bg-white rounded-l-xl rounded-r-xl"
                ></button>
              ) : (
                <button
                  key={item.id}
                  className="w-2 h-2 bg-white rounded-full "
                ></button>
              )
            )}
          </div>
        </div>

        {/* relative elements */}
        <div className="absolute top-8 -left-12">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute top-0 right-0">
          <svg
            width="77"
            height="148"
            viewBox="0 0 77 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5588)">
              <path
                d="M38.5882 9.9185C24.8818 26.4457 -11.2997 61.7583 7.60116 101.69C26.502 141.621 122.697 180.101 115.404 89.8871C112.665 55.9957 112.237 6.89761 100.605 -6.54347C81.2764 -28.8822 47.1443 -0.396586 38.5882 9.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5588"
                x="0.25"
                y="-16.8807"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5588"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 left-0">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 right-0">
          <svg
            width="50"
            height="165"
            viewBox="0 0 50 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5589)">
              <path
                d="M38.5882 26.9185C24.8818 43.4457 -11.2997 78.7583 7.60116 118.69C26.502 158.621 122.697 197.101 115.404 106.887C112.665 72.9957 112.237 23.8976 100.605 10.4565C81.2764 -11.8822 47.1443 16.6034 38.5882 26.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5589"
                x="0.25"
                y="0.119263"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5589"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {console.log(Params?.id?.split('&')[1],'iddddddd')}
      {showPopup &&
        createPortal(
          <DialogBox show={showPopup}>
            <PopUp
              button={Params?.id?.split('&')[1] == 'orgUser'?"Home Page":"Go to Login"}
              title="Password Created Successful!"
              main="Please login using your email and new password."
              body=""
              onClose={handleClose}
              onClick={() => Params?.id?.split('&')[1] == 'orgUser' ? window.location.href = 'https://www.eprakashak.in' : navigate(ROUTES.LOG_IN)}
            />
          </DialogBox>,
          document.body
        )}

      <ToastContainer position="top-center" />

    </div>
  );
}
