export default function Input({label, children, ...restprops }) {
  return (
    <div className="" >
      <label htmlFor={label} className="text-[14px] lg:text-h5 font-semibold text-secondary-dark " >{label}</label>
      <input
        id={label}
        {...restprops}
        className="pl-2 outline-primary-light font-semibold w-full lg:w-354 h-16 bg-primary-white border-1 lg:border-2 border-primary-light border-solid mt-2"
      />
    </div>
  );
}
