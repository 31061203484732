import { useState, useEffect } from "react";
import {
  Page,
  Navbar,
  BtnSmallDisabled,
  DialogBox,
  BtnSmall,
  IconBtn,
  PopUp,
} from "../components";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";

export default function GenerateAccessIds() {
  const [modals, setModals] = useState({
    payment: false,
  });

  const [inSufficientBalance, setInSufficientBalance] = useState(false);
  const [paid, setPaid] = useState(false);
  const [confirmOTP, setConfirmOTP] = useState(true);
  const [accessIdCreated, setAccessIdCreated] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  console.log(params.bookId, "book-id");

  const [selectedBooks, setSelectedBooks] = useState([
    {
      id: 0,
      author: "Allen Cooper",
      price: 123.4,
      desc: "Law Book - Redefining family law in India",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 1,
      author: "Allen Cooper",
      price: 123.4,
      desc: "Law Book - Redefining family law in India",
      imgSrc: null,
      validity: 1,
    },
    {
      id: 2,
      author: "Allen Cooper",
      price: 123.4,
      desc: "Law Book - Redefining family law in India",
      imgSrc: null,
      validity: 1,
    },
  ]);

  return (
    <Page active="wallet">
      <Navbar />
      <div className=" flex items-center">
        <button onClick={() => navigate(-1)} className="-ml-6 mr-2">
          <svg
            width="29"
            height="27"
            viewBox="0 0 29 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7109 21.3749C16.5303 21.3755 16.352 21.3384 16.1889 21.2664C16.0258 21.1944 15.8821 21.0892 15.7684 20.9587L9.93211 14.2087C9.75438 14.0074 9.65723 13.7549 9.65723 13.4943C9.65723 13.2337 9.75438 12.9812 9.93211 12.7799L15.9738 6.02994C16.1789 5.8002 16.4736 5.65572 16.7931 5.6283C17.1126 5.60087 17.4308 5.69274 17.6775 5.88369C17.9243 6.07465 18.0795 6.34905 18.1089 6.64653C18.1384 6.94401 18.0397 7.2402 17.8346 7.46994L12.4334 13.4999L17.6534 19.5299C17.8011 19.6951 17.895 19.8962 17.9238 20.1094C17.9527 20.3226 17.9153 20.5391 17.8162 20.7332C17.717 20.9273 17.5602 21.0909 17.3643 21.2046C17.1685 21.3183 16.9417 21.3775 16.7109 21.3749Z"
              fill="#494949"
            />
          </svg>
        </button>
        <h2 className="text-heading text-h4 font-semibold text-left ">
          Generate Access Ids
        </h2>
      </div>
      <div className="my-8 flex justify-between">
        <div className="w-2/3 max-w-[757px]  mr-8">
          {selectedBooks.map((book) => (
            <div className="flex bg-neutral-0 mb-4 h-[226px] border-1 border-solid border-primary-gray">
              <div className="w-40 bg-neutral-10"></div>
              <div className="p-4 w-id-card">
                <div className="flex justify-between items-center mb-6 ">
                  <h3 className="text-heading font-semibold text-p1 text-left">
                    {book.desc}
                  </h3>
                  <button className="outline-0 border-0">
                    <svg
                      width="4"
                      height="18"
                      viewBox="0 0 4 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.875 15.25C3.875 15.7473 3.67746 16.2242 3.32583 16.5758C2.97419 16.9275 2.49728 17.125 2 17.125C1.50272 17.125 1.02581 16.9275 0.674175 16.5758C0.322544 16.2242 0.125 15.7473 0.125 15.25C0.125 14.7527 0.322544 14.2758 0.674175 13.9242C1.02581 13.5725 1.50272 13.375 2 13.375C2.49728 13.375 2.97419 13.5725 3.32583 13.9242C3.67746 14.2758 3.875 14.7527 3.875 15.25ZM3.875 9C3.875 9.49728 3.67746 9.97419 3.32583 10.3258C2.97419 10.6775 2.49728 10.875 2 10.875C1.50272 10.875 1.02581 10.6775 0.674175 10.3258C0.322544 9.97419 0.125 9.49728 0.125 9C0.125 8.50272 0.322544 8.02581 0.674175 7.67417C1.02581 7.32254 1.50272 7.125 2 7.125C2.49728 7.125 2.97419 7.32254 3.32583 7.67417C3.67746 8.02581 3.875 8.50272 3.875 9ZM3.875 2.75C3.875 3.24728 3.67746 3.72419 3.32583 4.07583C2.97419 4.42746 2.49728 4.625 2 4.625C1.50272 4.625 1.02581 4.42746 0.674175 4.07583C0.322544 3.72419 0.125 3.24728 0.125 2.75C0.125 2.25272 0.322544 1.77581 0.674175 1.42417C1.02581 1.07254 1.50272 0.875 2 0.875C2.49728 0.875 2.97419 1.07254 3.32583 1.42417C3.67746 1.77581 3.875 2.25272 3.875 2.75Z"
                        fill="#9C9C9C"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center">
                    <label className="text-secondary-light font-[500] text-h5 mr-3">
                      Quantity
                    </label>
                    <div className="flex items-center justify-evenly bg-neutral-10 w-20 h-[38px]">
                      <IconBtn>
                        <svg
                          width="8"
                          height="3"
                          viewBox="0 0 8 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.21 0.145999H7.03V2.17H0.21V0.145999Z"
                            fill="#5F5D5D"
                            fillOpacity="0.79"
                          />
                        </svg>
                      </IconBtn>
                      <p className="text-heading text-h5">5</p>
                      <IconBtn>
                        <svg
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.894 5.83H0.924V3.916H3.894V0.967999H5.962V3.916H8.91V5.83H5.962V8.778H3.894V5.83Z"
                            fill="#5F5D5D"
                            fill-opacity="0.79"
                          />
                        </svg>
                      </IconBtn>
                    </div>
                  </div>
                  <div className="ml-8 flex items-center">
                    <label className="text-secondary-light text-h5 mr-3 font-[500]">
                      Validity
                    </label>
                    <div className="flex justify-evenly items-center bg-neutral-10 w-16 h-[38px]">
                      <p className="text-heading text-h5">1</p>
                      <button className="outline-0 border-0">
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.33301 3.75L6.29967 7.69231L10.2663 3.75"
                            stroke="#5F5D5D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <p className="mt-6 text-left text-p1 text-secondary-light flex items-center">
                  <span className="text-tertiary-dark text-h4 mr-1 font-semibold">
                    12000
                  </span>{" "}
                  credits
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-[397px]">
          <div className="flex flex-col  h-[226px] bg-neutral-0 border-1 py-4 px-6 border-solid border-primary-gray mb-4">
            <h3 className="text-heading font-semibold h-4 mb-8">
              Payment Details
            </h3>
            <div className="flex w-full justify-between items-center mb-4">
              <p className="text-secondary-dark font-[500] text-h5">
                Total cost
              </p>
              <p className="text-heading font-[500] text-h5">48000</p>
            </div>
            <div className="flex w-full justify-between items-center mb-6">
              <p className="text-secondary-dark font-[500] text-h5">
                Commission cost
              </p>
              <p className="text-heading font-[500] text-h5">3000</p>
            </div>
            <div className="flex w-full justify-between items-center">
              <p className="font-semibold text-tertiary-dark text-h4">
                Final credits
              </p>
              <p className="font-semibold text-heading text-h4">51000</p>
            </div>
          </div>
          <button
            onClick={() =>
              setModals((prevState) => ({ ...prevState, payment: true }))
            }
            className="w-full h-[56px] bg-primary-dark text-primary-white text-h5"
          >
            Confirm
          </button>
        </div>
      </div>
      {modals.payment && (
        <DialogBox>
          <div className="bg-primary-white flex flex-col justify-between relative">
            <div className=" border-[1.5px] border-solid border-primary-dark-10 p-6  w-[902px] h-[370px]">
              {!paid ? (
                <>
                  <h2 className="text-heading font-semibold text-left mb-3">
                    Payment
                  </h2>
                  <p className="text-secondary-light text-h5 text-justify mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                    aliquet ut facilisi nulla mauris iopsum. Pipsum dolor sit
                    ametre, consectetur adipiscing elit. At aliquet ut facilisi
                    nulla mauris.
                  </p>
                  <div className="flex items-center pl-4 mb-4 border-[1.5px] border-solid border-primary-dark-10 w-[730px] h-12">
                    <div className="w-6 h-6 text-white font-semibold bg-secondary-yellow flex justify-center items-center rounded-sm mr-3">
                      &#8377;
                    </div>
                    <p className="text-h5 text-secondary-dark font-semibold">
                      Total credits available:{" "}
                      <span className="text-heading  ml-6">30000</span>
                    </p>
                  </div>
                  <div className="mb-4 flex items-center pl-4 border-[1.5px] border-solid border-primary-dark-10 w-[730px] h-12">
                    <div className="w-6 h-6 text-white font-semibold bg-secondary-yellow flex justify-center items-center rounded-sm mr-3">
                      &#8377;
                    </div>
                    <p className=" text-h5 text-secondary-dark font-semibold">
                      Amount to pay:{" "}
                      <span className="text-heading  ml-[73px]">11500</span>
                    </p>
                  </div>
                  {inSufficientBalance && (
                    <p className="flex items-center text-secondary-light text-h5 text-left mb-2">
                      <span className="mr-2">
                        <svg
                          width="25"
                          height="22"
                          viewBox="0 0 25 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.0265 0C5.39521 0 0 4.85983 0 10.8333C0 16.8068 5.42058 21.6667 12.0833 21.6667C18.7461 21.6667 24.1667 16.8068 24.1667 10.8333C24.1667 4.85983 18.7207 0 12.0265 0ZM13.2917 16.25H10.875V14.0833H13.2917V16.25ZM13.2917 11.9167H10.875V5.41667H13.2917V11.9167Z"
                            fill="#FF3737"
                          />
                        </svg>
                      </span>
                      Your account balance is insufficient to complete this
                      transaction.
                      <RouterLink
                        to={ROUTES.ORDER_HISTORY}
                        className="ml-2 font-semibold text-tertiary-dark underline"
                      >
                        Add Money
                      </RouterLink>
                    </p>
                  )}
                  {inSufficientBalance ? (
                    <div className="text-right">
                      <BtnSmallDisabled
                        onClick={() =>
                          setModals((prevState) => ({
                            ...prevState,
                            addNew: false,
                          }))
                        }
                      >
                        Pay
                      </BtnSmallDisabled>
                    </div>
                  ) : (
                    <div className="text-right mt-10">
                      <BtnSmall
                        onClick={() => {
                          setModals((prevState) => ({
                            ...prevState,
                            addNew: false,
                          }));
                          setPaid(true);
                        }}
                      >
                        Pay
                      </BtnSmall>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h2 className="text-heading font-semibold text-left mb-4">
                    OTP has been sent for User Authentication
                  </h2>
                  <p className="text-secondary-light text-h5 text-justify mb-8">
                    One Time Password had been sent to your Mobile no. and Email
                    Address. Please enter the verification code. Tibdolor sit
                    ametre, consectetur adipiscing elit. At aliquet ut facilisi
                    nulla mauris.
                  </p>
                  <div className="flex items-center mb-8">
                    <input
                      type="text"
                      className="outline-0 border-0 font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                    />
                    <input
                      type="text"
                      className="outline-0 border-0 font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                    />
                    <input
                      type="text"
                      className="outline-0 border-0 font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                    />
                    <input
                      type="text"
                      className="outline-0 border-0 font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                    />
                  </div>

                  <p className="mb-6 text-left text-heading-false font-semibold text-p1">
                    Didn't receive the OTP?{" "}
                    <span className="text-secondary-light underline">
                      Resent in 0:59
                    </span>
                  </p>

                  {!confirmOTP ? (
                    <div className="text-right">
                      <BtnSmallDisabled
                        onClick={() =>
                          setModals((prevState) => ({
                            ...prevState,
                            addNew: false,
                          }))
                        }
                      >
                        Submit
                      </BtnSmallDisabled>
                    </div>
                  ) : (
                    <div className="text-right">
                      <BtnSmall
                        onClick={() =>
                          setModals((prevState) => ({
                            ...prevState,
                            addNew: false,
                          }))
                        }
                      >
                        Submit
                      </BtnSmall>
                    </div>
                  )}
                </>
              )}
            </div>
            <button
              onClick={() =>
                setModals((prevState) => ({ ...prevState, payment: false }))
              }
              className="absolute top-8 right-8"
            >
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                  fill="black"
                  fillOpacity="0.53"
                />
              </svg>
            </button>
          </div>
        </DialogBox>
      )}
      {accessIdCreated && (
        <DialogBox>
          <PopUp
            onClick={() => {
              setAccessIdCreated(false);
              navigate(ROUTES.CATALOGUE);
            }}
            title="Access Ids created"
            body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis enim purus interdum. Ut neque at adipiscing tortor facilisis sagittis libero. Ac bibendum vel blandit."
            main="You have successfully created 109 access ids"
            button="Confirm"
          />
        </DialogBox>
      )}
    </Page>
  );
}
