import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Page, Navbar } from "../../components";
import HeaderIcon from "../../components/Header_icon";
import { getOrderHistory } from "../../assets/Js/script";
import Loader from "../../components/loader";
import moment from "moment";

export default function OrderDetails() {
  const [book, setBook] = useState(null);

  const [orders, setOrders] = useState(null);
  const [createBookdata, setCreateBookData] = useState(null);

  const [canSelect, setCanSelect] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const Params = useParams();
  const navigate = useNavigate();
  console.log(Params, "uytt");

  function handleSelection(book) {
    setSelected((prevState) => {
      if (prevState.includes(book.id)) {
        return prevState.filter((item) => item !== book.id);
      } else {
        return [...prevState, book.id];
      }
    });
  }
  function removeDuplicateAndaddSimilar(array) {
    var output = [];

    array?.forEach(function (item) {
      var existing = output?.filter(function (v, i) {
        return v.parentId == item.parentId;
      });

      if (existing?.length) {
        var existingIndex = output?.indexOf(existing[0]);
        output[existingIndex]["Bauthors.bookAuthorName"] = output[
          existingIndex
        ]["Bauthors.bookAuthorName"].concat(item["Bauthors.bookAuthorName"]);
      } else {
        if (typeof item["Bauthors.bookAuthorName"] == "string")
          item["Bauthors.bookAuthorName"] = [item["Bauthors.bookAuthorName"]];
        output?.push(item);
      }
    });

    setCreateBookData(
      array?.filter(
        (data) => typeof data["Bauthors.bookAuthorName"] !== "string"
      )
    );
    return array?.filter(
      (data) => typeof data["Bauthors.bookAuthorName"] !== "string"
    );
  }
  console.log(createBookdata, 'lololo');

  useEffect(() => {
    (async () => {
      const { response, error } = await getOrderHistory();
      if (response.status === "success") {
        console.log(response.data)
        removeDuplicateAndaddSimilar([...response.data.bookData]);
        setOrders({ ...response.data });
        setIsLoading(false);

      }
    })();
  }, []);

  function filterData() {
    const test = [];
    const id = Params.id;

    if (orders, createBookdata) {
      for (var i = 0; i < createBookdata?.length; i++) {
        for (var x = 0; x < orders.orderIdGroup[id]?.length; x++) {
          if (
            createBookdata[i]?.parentId === orders?.orderIdGroup[id][x].bookId
          ) {
            test.push(createBookdata[i]);
          } else console.log("not working!");
        }
      }
    }
    if (test.length > 0) {
      setBook([...test]);
    } else {
      orders?.setData.forEach(set => {
        if (set.id == Params.id) {
          setBook([set])
        }
      })
    }
    return test;
  }

  console.log(book, 'kjkhjhjh')
  useEffect(() => {
    filterData();
  }, [orders]);

  function publishersName() {
    if (orders) {
      return orders.assignedFromNames.filter(
        (elm) => elm.orderId === Params.id
      );
    }
  }

  console.log(publishersName(), "grgttg");
  function getDate(id) {
    // if (orders?.orderIdGroup[id]) {
    return orders?.orderIdGroup[id]?.[0].createdAt;

    // } else {
    //   console.log(id, 'iddddddd  ')
    //   return orders.setOrderIdGroup[id][0].createdAt;

    // }
  }

  function getLeftaccessId(id) {
    for (let i = 0; i < orders?.orderIdGroup[Params.id]?.length; i++) {
      if (orders?.orderIdGroup[Params.id]?.[i].bookId === id) {
        return orders?.orderIdGroup[Params.id]?.[i];
      }
    }
  }

  function getLeftaccessIdSet(id) {
    for (let i = 0; i < orders?.setOrderIdGroup[Params.id].length; i++) {
      if (orders?.setOrderIdGroup[Params.id][i].setId === id) {
        return orders?.setOrderIdGroup[Params.id][i];
      }
    }
  }

  function calculateExpiry(data) {
    if (!data) return false
    const date1 = Date.parse(data.expiresOn);

    const date2 = Date.parse(data.createdAt);

    const difference = date1 - date2;

    const day = Math.round(difference / (3600000 * 24));

    const percent = day / (data.validity * 365);

    return { day, percent: percent * 100 };
  }

  return (
    <Page active="Users">
      <Navbar />
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          <h1 className="hidden lg:block lg:text-heading font-semibold text-montserrat lg:text-h1 my-4">
            Order history
          </h1>
          <HeaderIcon heading={"Order history"} />
          <div className=" mt-2 lg:mt-0 pr-5 lg:pr-0 flex justify-between">
            <p className="text-secondary-light font-semibold text-h5 text-montserrat">
            View all Purchased Books & Sets by your Organisation
            </p>
            <div className="flex">
              {/* <button
          
          className="ml-14 underline text-montserrat font-semibold text-[18px] text-tertiary-dark"
        >
          Add a new user
        </button> */}
            </div>
          </div>

          <div className="border-0 w-full my-2 lg:my-8 lg:p-4 lg:px-6 py-4 lg:border-1 border-solid border-primary-dark-20">
            <h2 className="hidden text-h4 lg:flex items-center font-semibold text-heading">
              <button
                className="mr-3 hidden lg:block"
                onClick={() => navigate(-1)}
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.05363 15.7514C6.87311 15.752 6.69475 15.7149 6.53165 15.6429C6.36855 15.5708 6.22486 15.4657 6.11113 15.3352L0.274881 8.58516C0.0971576 8.38386 0 8.13136 0 7.87078C0 7.61021 0.0971576 7.35771 0.274881 7.15641L6.31655 0.406409C6.52165 0.176665 6.81638 0.0321876 7.13589 0.00476044C7.45541 -0.0226668 7.77354 0.0692029 8.0203 0.260159C8.26706 0.451115 8.42224 0.725515 8.4517 1.02299C8.48116 1.32047 8.38248 1.61666 8.17738 1.84641L2.77613 7.87641L7.99613 13.9064C8.14389 14.0715 8.23775 14.2726 8.2666 14.4859C8.29546 14.6991 8.2581 14.9156 8.15895 15.1097C8.0598 15.3038 7.90301 15.4674 7.70712 15.5811C7.51124 15.6948 7.28446 15.7539 7.05363 15.7514Z"
                    fill="#494949"
                  />
                </svg>
              </button>
              All Orders
            </h2>
            <div className="my-0 lg:my-6 py-2 px-0 lg:px-5  border-0 md:p-3  md:border-1 border-solid border-[rgba(174,174,174,0.36)]">
              <div className="mb-12 mt-4 flex justify-between items-center">
                <div className="flex flex-col items-start">
                  <h3 className=" text-secondary-dark text-h3 lg:text-h4 text-montserrat font-semibold">
                    Order Id: <span className="uppercase">{Params.id}</span>
                  </h3>
                  <p className="text-h4 lg:text-h5 text-secondary-light">
                    {moment(getDate(Params.id) ?? book[0].createdAt).format("DD MMMM, YYYY")}
                  </p>
                  <p className="text-h4 lg:text-h5 text-secondary-light">
                    {publishersName()[0]?.brandName}
                  </p>
                </div>
                {/* <div className="flex flex-row">
                  {canSelect ? (
                    <>
                      <button
                        onClick={() => setCanSelect(false)}
                        className="hidden lg:block outline-0 border-0 font-semibold text-h5 text-secondary-dark mr-10"
                      >
                        Cancel
                      </button>
                      <button className="hidden lg:block border-1 border-solid border-[rgba(174,174,174,0.39)] text-heading py-3 px-[38px] bg-[#fbfafb] font-bold">
                        Renew selected
                      </button>
                      <button
                        onClick={() => setCanSelect(false)}
                        className=" block lg:hidden  bg-primary-dark text-primary-white border-1 border-solid border-[rgba(174,174,174,0.39)] lg:text-heading py-3 px-[38px] lg:bg-[#fbfafb] font-bold"
                      >
                        Select
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setCanSelect(true)}
                      className="bg-primary-dark text-primary-white border-1 border-solid border-[rgba(174,174,174,0.39)] lg:text-heading py-3 px-[38px] lg:bg-[#fbfafb] font-bold"
                    >
                      Select
                    </button>
                  )}
                </div> */}
              </div>

              {book?.map((item) =>
                canSelect ? (
                  <div key={item?.id} className="relative flex">
                    <div className="absolute top-6 right-2 lg:relative lg:h-[206px] w-6 mr-3 flex justify-center items-center">
                      {selected.includes(item?.id) ? (
                        <div className=" bg-primary-dark flex justify-center items-center rounded-full w-6 h-6">
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 15 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.313241 3.75601C0.51387 3.57208 0.785945 3.46875 1.06963 3.46875C1.35332 3.46875 1.6254 3.57208 1.82603 3.75601L5.60906 7.22533L13.173 0.287674C13.2723 0.196515 13.3903 0.124192 13.5201 0.0748331C13.6499 0.025474 13.789 4.5617e-05 13.9295 6.13195e-08C14.0701 -4.54943e-05 14.2092 0.0252926 14.3391 0.0745675C14.4689 0.123842 14.5869 0.196089 14.6863 0.287183C14.7857 0.378277 14.8646 0.486433 14.9184 0.605478C14.9722 0.724522 14.9999 0.852123 15 0.980994C15 1.10987 14.9724 1.23748 14.9187 1.35656C14.865 1.47564 14.7862 1.58385 14.6868 1.67501L5.60906 10L0.313241 5.14335C0.112673 4.95936 0 4.70984 0 4.44968C0 4.18952 0.112673 3.94 0.313241 3.75601Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="w-6 h-6 bg-neutral-50 rounded-full"></div>
                      )}
                    </div>
                    <div
                      onClick={() => handleSelection(item)}
                      className=" flex justify-between items-center w-full h-[206px] border-1 border-solid border-primary-dark-20 bg-[#fbfafb] mb-6"
                    >
                      <div className="flex gap-3">
                        <div
                          style={{ background: item?.backgroundColor }}
                          className="flex items-center justify-center bg-neutral-10 w-fit lg:w-[170px] h-[203px] lg:h-[206px] py-5 bg-[#ccc] px-2"
                        >
                          {item?.imageCoverFront && (
                            <img
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item?.imageCoverFront}`}
                              alt="Cover photo"
                              className="h-full object-cover w-[110px] h-[136px] "
                            />
                          )}
                        </div>
                        <div className="py-3 lg:w-fit w-[229px] lg:px-6 lg:py-4 flex flex-col lg:justify-between">
                          {/* <p
                            className={`hidden lg:block text-secondary-light text-[14px] uppercase`}
                          >
                            {item?.parentId}
                          </p> */}
                          <p className="leading-[22px] text-heading text-left font-semibold text-h5">
                            {item?.title}
                          </p>
                          <p className="text-p1 mb-[20px] text-secondary-dark font-semibold">
                            {item["Bauthors.bookAuthorName"]?.map(
                              (auther, index, arr) => (
                                <span>
                                  {arr.indexOf(arr[index]) ===
                                    arr.length - 1 ? (
                                    auther
                                  ) : (
                                    <span>{auther},&nbsp;</span>
                                  )}
                                </span>
                              )
                            )}{" "}
                          </p>
                          <p className="hidden lg:block text-[rgba(112,41,99,0.65)] font-semibold text-montserrat">
                            {getLeftaccessId(item.parentId).totalCount} Access
                            Ids
                          </p>
                          <div className="flex items-center mt-[30px] lg:mt-0">
                            <div className="w-[106px] mt-[22px] lg:mt-0 lg:w-32 h-2 bg-primary-light rounded-full">
                              <div
                                className="h-full bg-primary-dark rounded-full"
                                style={{
                                  width:
                                    calculateExpiry(
                                      getLeftaccessId(item.parentId)
                                    ).percent + "%",
                                }}
                              ></div>
                            </div>
                            <p className="text-sm text-secondary-light ml-4">
                              {
                                calculateExpiry(getLeftaccessId(item.parentId))
                                  .day
                              }{" "}
                              days
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <button className="hidden lg:block border-1 mr-6 border-solid border-primary-dark-20 text-p1 font-semibold w-[183px] h-12">
                        Renew
                      </button> */}
                    </div>
                  </div>
                ) : (
                  <div className=" flex justify-between items-center w-full h-[206px] border-1 border-solid border-primary-dark-20 bg-[#fbfafb] mb-6">
                    <div className="flex gap-3">
                      <div
                        style={{ background: item?.backgroundColor }}
                        className="flex items-center justify-center bg-neutral-10 w-fit lg:w-[170px] h-[203px] lg:h-[206px] py-5 bg-[#ccc] px-2"
                      >
                        {item?.imageCoverFront && (
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item?.imageCoverFront}`}
                            alt="Cover photo"
                            className="h-full object-cover w-[110px] h-[136px] "
                          />
                        )}
                        {item?.cover && (
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${item?.cover}`}
                            alt="Cover photo"
                            className="h-full object-cover w-[110px] h-[136px] "
                          />
                        )}
                      </div>
                      <div className="py-3 lg:w-fit w-[229px] lg:px-6 lg:py-4 flex flex-col lg:justify-between">
                        {/* <p className=" text-secondary-light whitespace-nowrap overflow-hidden sm:w-full w-[100px] text-ellipsis text-[14px] uppercase">
                          {item?.parentId}
                        </p> */}
                        <p className="leading-[22px] text-heading text-left font-semibold text-h5">
                          {item?.title}
                        </p>
                        <p className="text-p1 mb-[20px] text-secondary-dark font-semibold">
                          {item["Bauthors.bookAuthorName"]?.map(
                            (auther, index, arr) => (
                              <span>
                                {arr.indexOf(arr[index]) === arr.length - 1 ? (
                                  auther
                                ) : (
                                  <span>{auther},&nbsp;</span>
                                )}
                              </span>
                            )
                          )}{" "}
                        </p>
                        <p className="hidden lg:block text-[rgba(112,41,99,0.65)] font-semibold text-montserrat">
                          {getLeftaccessId(item.parentId)?.totalCount ?? book[0].totalCount} Access Ids
                        </p>
                        <div className="flex items-center mt-[30px] lg:mt-0">
                          <div className="w-[106px] mt-[22px] lg:mt-0 lg:w-32 h-2 bg-primary-light rounded-full">
                            <div
                              className="h-full bg-primary-dark rounded-full"
                              style={{
                                width:
                                  calculateExpiry(
                                    getLeftaccessId(item.parentId)
                                  ).percent ?? calculateExpiry(
                                    book[0]
                                  ).percent ?? + "%",
                              }}
                            ></div>
                          </div>
                          <p className="text-sm text-secondary-light ml-4">
                            {
                              calculateExpiry(getLeftaccessId(item.parentId))
                                .day ?? calculateExpiry(   book[0]).day
                            }{" "}
                            days
                          </p>
                        </div>
                        {/* <button className="w-[88px] h-[30px] mt-4 lg:hidden bg-primary-dark text-primary-white font-semibold">
                          Renew
                        </button> */}
                      </div>
                    </div>
                    {/* <button className="hidden lg:block border-1 mr-6 border-solid border-primary-dark-20 text-p1 font-semibold w-[183px] h-12">
                      Renew
                    </button> */}
                  </div>
                )
              )}
            </div>
            {/* <div className="flex justify-center bg-thumb text-primary-white px-1 py-2 mt-4 my-[6rem] lg:hidden">
          <p>Add a new user</p>
        </div> */}
          </div>
        </>
      )}
    </Page>
  );
}
