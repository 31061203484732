import { useState, useEffect } from "react";
import { Params, useParams } from "react-router-dom";
import UserPic from "../assets/images/userPic.png";
import { Page, Navbar, DialogBox } from "../components";
import { useNavigate } from "react-router-dom";
import HeaderIcon from "../components/Header_icon";
import moment from "moment";
import {
  allOrganisationUser,
  getOrderHistoryofUser,
} from "../assets/Js/script";
import Loader from "../components/loader";

export default function UserOrderHistory() {
  const Params = useParams();
  const [orders, setOrders] = useState([1, 2, 3]);
  const [books, setBooks] = useState(null);
  const [detail, setDetail] = useState(null);
  const [orderDetails, setOrderDetails] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  ///////////////// api //////////////
  const id = Params.order_history.split("=")[1];

  useEffect(() => {
    async function getuserdata() {
      setIsLoading(true)
      const { response, error } = await allOrganisationUser("status");
      if (response.status === "success") {
        let value = [];
        setIsLoading(false)

        value.push(
          response.data.orgUsers.filter((elem, index) => {
            return elem.id === id;
          })
        );
        console.log(value, "tfat");
        setDetail({ ...value[0][0] });
      }
    }
    getuserdata();

    async function getuserhistory() {
      setBooks(true)
      const { response, error } = await getOrderHistoryofUser(id);
      if (response.status === "success") {
        console.log(response, 'lololo')
        setIsLoading(false)
        setBooks({ ...response.data });
      }
    }

    getuserhistory();
  }, []);

  console.log(detail, "detail");

  ///////////////// api //////////////

  function filterData(bookid) {
    const test = [];

    if (books) {
      for (var i = 0; i < books?.bookData?.length; i++) {
        for (var x = 0; x < books?.orderIdGroup[bookid].length; x++) {
          if (
            books?.bookData[i]?.parentId ===
            books?.orderIdGroup[bookid][x]?.bookId
          ) {
            test.push(books?.bookData[i]);
            // console.log(books?.orderIdGroup[bookid][x], "match value");
          } else console.log("not working!");
        }
      }
    }
    
    return test;
  }

  function getDate(bookid) {
    return books?.orderIdGroup[bookid].find((item) => item.orderId === bookid)
      .createdAt;
  }

  return (
    <Page active="Users">
      <Navbar />
     {isLoading ? (<Loader show={isLoading}/> ) : (
      <div className="lg:w-100vw">
        <h1
          className={
            "hidden lg:block text-h4 text-tertiary-dark lg:text-heading lg:text-left font-semibold text-montserrat lg:text-h1 my-4"
          }
        >
          <button className="mr-3 md:hidden" onClick={() => navigate(-1)}>
            <svg
              width="12"
              height="18"
              viewBox="0 0 12 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                fill="#702963"
              />
            </svg>
          </button>
          Users
        </h1>
        {orderDetails ? null : <HeaderIcon heading={"Users"} />}
        <div className="hidden lg:flex justify-between">
          {/* <p className="text-secondary-light font-semibold text-h5 text-montserrat">
            Lorem ipsum dolor sit amet, consectetu
          </p> */}
          {/* <div className="flex ">
            <button className="ml-14 underline text-montserrat font-semibold text-[18px] text-tertiary-dark">
              Add a new user
            </button>
          </div> */}
        </div>

        <div className="flex flex-col lg:flex-row my-8 justify-between w-full">
          <div className="flex flex-col lg:gap-y-5 items-center lg:items-start ">
            <div className="flex flex-col justify-evenly items-center lg:bg-primary-extra-light border-primary-dark-20 lg:border-1 border-solid w-263 min-h-[160px] max-h-[205px] lg:max-h-none lg:h-205 lg:mr-4">
              <p className="text-p1 text-secondary-light">Publisher Name</p>
              <div
                className={`bg-[#bb7aaf] flex justify-center items-center w-24 h-24 rounded-full`}
              >
                {detail?.companyPhoto ? (
                  <img src={detail?.companyPhoto} alt="img" />
                ) : (
                  <span
                    className={`text-[50px] uppercase font-bold text-[#fff]`}
                  >
                    {detail?.fullName[0]}
                  </span>
                )}
              </div>
              <h2 className="h2 text-heading font-semibold capitalize">
                {detail?.fullName}
              </h2>
            </div>
            <div className="flex pl-12 lg:pl-8 flex-col lg:py-4  items-start lg:bg-primary-extra-light border-primary-dark-20 lg:border-1 border-solid w-263  lg:mr-4">
              <div className="flex lg:mb-6">
                <svg
                  style={{ marginTop: "3px" }}
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden lg:block"
                >
                  <path
                    d="M5.59338 3.66699C4.92532 3.66699 4.28463 3.92222 3.81224 4.37653C3.33985 4.83084 3.07446 5.44702 3.07446 6.08951V6.33298L10.6312 10.2466L18.188 6.33419V6.08951C18.188 5.44702 17.9226 4.83084 17.4502 4.37653C16.9778 3.92222 16.3371 3.66699 15.6691 3.66699H5.59338Z"
                    fill="#7D7C7C"
                  />
                  <path
                    d="M18.188 7.70898L10.9297 11.4675C10.838 11.515 10.7354 11.5399 10.6312 11.5399C10.527 11.5399 10.4245 11.515 10.3327 11.4675L3.07446 7.70898V13.3571C3.07446 13.9996 3.33985 14.6158 3.81224 15.0701C4.28463 15.5244 4.92532 15.7796 5.59338 15.7796H15.6691C16.3371 15.7796 16.9778 15.5244 17.4502 15.0701C17.9226 14.6158 18.188 13.9996 18.188 13.3571V7.70898Z"
                    fill="#7D7C7C"
                  />
                </svg>

                <div className="ml-1 flex justify-between flex-col">
                  <h4 className="hidden lg:block text-p1 font-semibold">
                    Email address
                  </h4>
                  <p className="text-s text-secondary-light font-semibold">
                    {detail?.email}
                  </p>
                </div>
              </div>
              <div className="flex">
                <svg
                  style={{ marginTop: "3px" }}
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden lg:block"
                >
                  <path
                    d="M16.3823 15.3398L13.1317 12.206C12.978 12.0579 12.7761 11.9789 12.5685 11.9857C12.361 11.9925 12.1641 12.0846 12.0193 12.2424L10.1058 14.3291C9.64515 14.2358 8.71915 13.9297 7.76596 12.9216C6.81277 11.9101 6.52409 10.9257 6.43853 10.4407L8.40489 8.41086C8.55395 8.2575 8.64089 8.04866 8.64731 7.82854C8.65373 7.60841 8.57912 7.39428 8.43927 7.23145L5.48454 3.78566C5.34463 3.62232 5.15019 3.52324 4.94249 3.50947C4.7348 3.49569 4.53023 3.56831 4.37222 3.71189L2.63696 5.2898C2.49871 5.43692 2.41619 5.63273 2.40506 5.84008C2.39307 6.05205 2.16436 11.0732 5.83639 14.9684C9.03981 18.3641 13.0525 18.6126 14.1576 18.6126C14.3192 18.6126 14.4183 18.6075 14.4447 18.6058C14.6402 18.5942 14.8248 18.5063 14.9629 18.3591L16.4502 16.5183C16.5862 16.3513 16.6552 16.1346 16.6425 15.9144C16.6298 15.6942 16.5364 15.4879 16.3823 15.3398Z"
                    fill="#7D7C7C"
                  />
                </svg>

                <div className="ml-1 flex items-center justify-between flex-col">
                  <h4 className="hidden lg:block text-p1 font-semibold">
                   Mobile number
                  </h4>
                  <p className="text-s  ml-8 lg:ml-0 text-secondary-light text-center font-semibold">
                    +91 {detail?.mobile}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between mt-6 lg:mt-0 lg:px-6 py-4 lg:bg-primary-extra-light border-primary-dark-20 md:p-3 md:border-1 border-solid w-full lg:w-[calc(100%-280px)] lg:max-w-921 md:w-[92vw]">
            <h2 className="lg:text-h4 text-h5 font-semibold text-heading">
              Order History
            </h2>
            <div className="my-4">
              {books?.mySet?.map((item, index) => (
                <div
                  key={index}
                  className="mb-4 p-4 border-1 border-primary-dark-10 bg-primary-white"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <h3 className=" text-secondary-dark text-sm lg:text-h4 text-montserrat font-semibold">
                        Order Id: <span className="uppercase">{item}</span>
                      </h3>
                      <p className="text-sm lg:text-h5 text-secondary-light">
                        {moment(getDate(item)).format("DD MMMM, YYYY")}
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        navigate(`/order_history=${id}/order_details=${item}`)
                      }
                      className="hidden lg:block underline text-tertiary-dark font-bold"
                    >
                      View More
                    </button>
                  </div>
                  <div className="my-3 flex gap-2 flex-row overflow-x-scroll">
                    {filterData(item).map((item, index) => {
                      return (
                        <div className=" bg-neutral-0 justify-between rounded-[10px] lg:p-4 flex w-[223px] h-[96px] lg:w-[266px] lg:h-[134px]">
                          <div style={{background: item.backgroundColor}} className="flex justify-center items-center  bg-neutral-50 w-[73px] p-2 pr-0 lg:p-0 lg:w-[80px] h-[96px] lg:h-[107px] mr-3 bg-[#A93030] ">
                            {item?.imageCoverFront && (
                              <img
                                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item?.imageCoverFront}`}
                                alt="Cover photo"
                                className="h-full object-cover w-[55.5px] h-[77.8px] "
                              />
                            )}
                          </div>
                          <div className="flex flex-col w-[150px] py-[5px] lg:py-0 lg:w-[calc(100%-92px)] justify-between">
                            {/* <p className="text-secondary-light text-medium text-[14px] uppercase">
                              #law1223
                            </p> */}
                            <p className="text-left text-heading-false font-semibold text-[16px] leading-[19px] lg:text-[15px] ">
                              {item?.title}
                            </p>
                            <p className="text-montserrat text-[14px] lg:text-[13px] font-semibold text-secondary-dark">
                            {item.Bauthors?.map((auther, index, arr) => (
                                  <span>
                                    {arr.indexOf(arr[index]) === arr.length - 1
                                      ? auther.bookAuthorName
                                      : `${auther.bookAuthorName}, `}
                                  </span>
                                ))}                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="text-right mb-2">
                    {/* <button className="hidden lg:block border-[0.5px] border-solid border-[#cacaca] py-4 px-[65px] text-h5 text-heading font-bold">
                      Renew
                    </button> */}
                    <button
                      onClick={() => setOrderDetails(true)}
                      className="lg:hidden underline text-tertiary-dark font-bold mt-4 text-p"
                    >
                      View More
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
     )}
    </Page>
  );
}
