// const url = 'https://www.apis.eprakashak.in/api/v1/';
const url = process.env.REACT_APP_API_URL
// const url = 'https://d1ekv1v577b7n7.cloudfront.net/api/v1/';

export const LOG_IN = `${url}all/users/login`;
export const LOG_OUT = `${url}all/users/logout/`;
export const SIGN_UP = `${url}all/users/signup`;
export const FORGOT_PASSWORD = `${url}all/users/forgotPassword`;
export const RESET_PASSWORD = `${url}all/users/resetPassword`;
export const GET_PROFILE = `${url}all/users/myProfile`;
export const WALLET = `${url}publisher/publishers/myWallet`;
export const UPLOAD_BOOK = `${url}publisher/books`


export const GET_MY_ORGANISATION = `${url}organisation/organisations/my`;
export const UPDATE_ORGANISATION_PICTURE = `${url}all/portals`;
export const ADD_NEW_ORGANISATION = `${url}organisation/orgAdmins/`;
export const GET_ALL_ORGANISERS = `${url}organisation/orgAdmins/`;
export const MAKE_PRIMARY = `${url}organisation/orgAdmins/transferPcp/`;
export const DELETE_MEMBER = `${url}organisation/orgAdmins/`;
export const UPDATE_PERSONAL_PROFILE = `${url}all/users/updateMe`;
export const GET_MY_BOOK_HISTORY = `${url}organisation/organisations/orderHistory`;
export const ALL_ORGANISATION_USER = `${url}organisation/orgUsers/`;
export const ADD_NEW_ORGANISATION_USER = `${url}organisation/orgUsers/`;
export const GET_ORDER_HISTORY_OF_USER = `${url}organisation/orgUsers/`;
export const GET_ALL_DEPARTMENTS = `${url}organisation/departments/`;
export const GET_A_DEPARTMENT = `${url}organisation/departments/`;
export const CREATE_DEPARTMENT = `${url}organisation/departments/`; 
export const SEARCH_USER = `${url}organisation/orgUsers/search?text=`; 
export const UPDATE_ORGANISATION_NAME =`${url}organisation/departments/`; 
export const GET_USER_OF_DEPARTMENT = `${url}organisation/departments/`;
export const ASSIGN_USER_TO_DEPARTMENT = `${url}organisation/orgUsers/assignDept`;

export const DOWNLOAD_EXCEL_FILE = `${url}organisation/orgUsers/download`;
export const ADD_USERE_IN_BULK = `${url}organisation/orgUsers/upload`;
/// catalouge ///////

export const GET_ALL_BOOK = `${url}organisation/books`;
export const GET_BOOK_DETAILS = `${url}organisation/books/`;

////// sets ////////

export const GET_All_SETS = `${url}organisation/organisations/sets`;
export const GET_SET_DETAILS = `${url}organisation/organisations/sets/`;

//// cart ////////

/// cart post /////////////////

export const ADD_BOOK_TO_CART = `${url}organisation/cart/`;
export const ADD_USERS_TO_CART = `${url}organisation/cart/user`;
export const ADD_DEPARTMENT_TO_CART = `${url}organisation/cart/department`;
export const ADD_SET_TO_CART = `${url}organisation/cart/sets`;

export const GENERATE_SET_ORDERID_FOR_INDIVIUAL = `${url}organisation/cart/sets/confirmToIndividual`;
export const GENERATE_SET_ORDERID_FOR_DEPARTMENT = `${url}organisation/cart/sets/confirmToDepartment`;
export const VERIFY_OTP_FOR_SET = `${url}organisation/cart/sets/verify`;

export const GENERATE_ORDERID_FOR_INDIVIUAL = `${url}organisation/cart/confirmToIndividual `;
export const GENERATE_ORDERID_FOR_DEPARTMENT = `${url}organisation/cart/confirmToDepartment`;
export const VERIFY_OTP = `${url}organisation/cart/verify`;
/// cart get /////////////////
export const GET_ALL_BOOK_FOR_ASSIGN = `${url}organisation/books/cart`;
export const GET_ALL_SET_FOR_ASSIGN = `${url}organisation/cart/availableSets`;

export const GET_SETS_FROM_CART = `${url}organisation/cart/sets`;
export const GET_BOOKS_FROM_CART = `${url}organisation/cart`;
export const GET_USERS_FROM_CART = `${url}organisation/cart/user`;
export const GET_DEPARTMENTS_FROM_CART = `${url}organisation/cart/department`;


/// cart delete ///////////////
export const DELETE_SET_FROM_CART = `${url}organisation/cart/sets/`;
export const DELETE_BOOK_FROM_CART = `${url}organisation/cart/`;
export const DELETE_USERS_FROM_CART = `${url}organisation/cart/user/`;
export const DELETE_DEPARTMENT_FROM_CART = `${url}organisation/cart/department/`;

export const EMPTY_CART = `${url}organisation/cart/`;


// settings

export const UPDATE_PASSWORD = `${url}all/users/updateMyPassword`;


export const GET_ALL_TICKET = `${url}all/tickets/`;
export const SEARCH_FOR_TICKET = `${url}all/tickets/search?portal=`;
export const CREATE_NEW_TICKET = `${url}all/tickets/`;
export const Add_COMMENTS = `${url}all/tickets/`;
export const GET_ALL_COMMENTS = `${url}all/tickets/`;
export const UPDATE_STATUS = `${url}all/tickets/`;


// book search

export const SEARCH_BOOK_BY_TITLE = `${url}organisation/books/search?keyword=`;
export const SEARCH_BOOK_BY_BOOKID = `${url}organisation/books/searchByOrderId?orderId=`;




// delete Profile picture
export const DELETE_PROFILE_PICTURE = `${url}all/users/removePhoto`; 
export const DELETE_BUSINESS_PROFILE = `${url}all/portals/remove`; 

/// notification 

export const GET_ALL_NOTIFICATION = `${url}organisation/notifications`;
export const UPDATE_NOTIFICATION = `${url}organisation/notifications`;
