import { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { Page, Navbar } from "../../components";
import Loader from "../../components/loader";
import HeaderIcon from "../../components/Header_icon";
import { getOrderHistory } from "../../assets/Js/script";
import moment from "moment";
import * as ROUTES from "../../constant/routes";

export default function OrderHistory() {
  const [orders, setOrders] = useState([1, 2, 3]);
  const [books, setBooks] = useState(null);
  const [sets, setSets] = useState([])
  const [createBookdata, setCreateBookData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function removeDuplicateAndaddSimilar(array) {
    var output = [];

    array?.forEach(function (item) {
      var existing = output?.filter(function (v, i) {
        return v.parentId == item.parentId;
      });

      if (existing?.length) {
        var existingIndex = output?.indexOf(existing[0]);
        output[existingIndex]["Bauthors.bookAuthorName"] = output[
          existingIndex
        ]["Bauthors.bookAuthorName"].concat(item["Bauthors.bookAuthorName"]);
      } else {
        if (typeof item["Bauthors.bookAuthorName"] == "string")
          item["Bauthors.bookAuthorName"] = [item["Bauthors.bookAuthorName"]];
        output?.push(item);
      }
    });

    setCreateBookData(
      array?.filter(
        (data) => typeof data["Bauthors.bookAuthorName"] !== "string"
      )
    );
    return array?.filter(
      (data) => typeof data["Bauthors.bookAuthorName"] !== "string"
    );
  }

  useEffect(() => {
    async function ordHistory() {
      setIsLoading(true);
      const { response, error } = await getOrderHistory();
      if (response.status === "success") {
        console.log(response, "log");
        setIsLoading(false);
        setBooks({ ...response.data });
        removeDuplicateAndaddSimilar([...response.data.bookData]);
        setSets(response.data.setData ?? [])
      }
    }
    ordHistory();
  }, []);

  function filterData(bookid) {
    const test = [];

    if ((books, createBookdata)) {
      for (var i = 0; i < createBookdata?.length; i++) {
        for (var x = 0; x < books?.orderIdGroup[bookid].length; x++) {
          if (
            createBookdata[i]?.parentId ===
            books?.orderIdGroup[bookid][x]?.bookId
          ) {
            test.push(createBookdata[i]);
          } else console.log("not working!");
        }
      }
    }
    console.log(test, "ooooooooooo");
    return test;
  }

  function getDate(bookid) {
    return books?.orderIdGroup[bookid].find((item) => item.orderId === bookid)
      .createdAt;
  }
  const getOrderIdOfSet = (setId) => {
    let orderId
    books.mySetSet?.forEach(oid => {
      if (books.setOrderIdGroup[oid][0].setId == setId) {
        orderId = oid
      }

    })
    return orderId
  }
  return (
    <Page active="Users">
      <Navbar />
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          <h1 className="hidden lg:block lg:text-heading font-semibold text-montserrat lg:text-h1 my-4">
            Order History
          </h1>
          <HeaderIcon heading={"Users"} />
          <div className="flex justify-between flex-col">
            <h2 className="my-5 text-base md:hidden font-semibold text-heading">
              All Orders
            </h2>
            <p className="text-sm text-secondary-light font-semibold md:text-h5 text-montserrat">
              {/* View &#38; Request all Books in your Organisation */}
              View all Purchased Books &#38; Sets by your Organisation
            </p>
            {/* <div className="flex ">
          <button
            
            className="ml-14 underline text-montserrat font-semibold text-[18px] text-tertiary-dark"
          >
            Add a new user
          </button>
        </div> */}
          </div>

          <div className="my-2 bg-white-700 border-primary-white flex flex-col justify-between md:my-10 md:px-6 py-4 md:bg-primary-extra-light md:border-primary-dark-20 border-1 border-solid max-w-921 ">
            <h2 className="hidden md:block text-h4 font-semibold text-heading">
              All Orders
            </h2>
            <div className="my-4">
              {books?.mySet?.map((item) => (
                <div
                  key={item}
                  className="mb-4 p-4 border-1 border-primary-dark-10 bg-primary-white "
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <h3 className=" text-secondary-dark text-sm md:text-h4 text-montserrat font-semibold">
                        Order Id: <span className="uppercase">{item}</span>
                      </h3>
                      <p className="text-sm md:text-h5 text-secondary-light">
                        {moment(getDate(item)).format("DD MMMM, YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="my-3 flex gap-2 flex-row overflow-x-scroll">
                    {filterData(item)?.map((item, index) => {
                      return (
                        <div className=" bg-neutral-0 justify-between rounded-[10px] lg:p-4 flex w-[223px] h-[96px] lg:w-[266px] lg:h-[134px]">
                          <div
                            style={{ background: item.backgroundColor }}
                            className="flex justify-center items-center  bg-neutral-50 w-[73px] p-2 pr-0 lg:p-0 lg:w-[80px] h-[96px] lg:h-[107px] mr-3 bg-[#A93030] "
                          >
                            {item?.imageCoverFront && (
                              <img
                                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item?.imageCoverFront}`}
                                alt="Cover photo"
                                className="h-full object-cover w-[55.5px] h-[77.8px] "
                              />
                            )}
                          </div>
                          <div className="flex flex-col w-[150px] py-[5px] lg:py-0 lg:w-[calc(100%-92px)] justify-between">
                            {/* <p className="text-secondary-light text-medium text-[14px] uppercase">
                          #law1223
                        </p> */}
                            <p className="text-left text-ellipsis overflow-hidden whitespace-nowrap w-[150px] text-heading-false font-semibold text-[16px] leading-[19px] lg:text-[15px] ">
                              {item?.title}
                            </p>
                            <p className="text-montserrat text-[14px] flex lg:text-[13px] font-semibold text-secondary-dark">

                              {item["Bauthors.bookAuthorName"].map((auther, index, arr) => (
                                <span>
                                  {arr.indexOf(arr[index]) === arr.length - 1
                                    ? auther
                                    : (<span>{auther},&nbsp;</span>)}
                                </span>
                              ))}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                  </div>

                  <div className="text-right mb-2">
                    <button
                      onClick={() =>
                        navigate(`/order-history/order-details/${item}`)
                      }
                      className="underline text-tertiary-dark font-bold"
                    >
                      View Order
                    </button>
                  </div>
                  {/* <div className="flex justify-between items-end mb-0 md:mb-2">
                <p className="text-tertiary-dark font-semibold md:text-h5 text-xl">
                  LawLegat12 Publishers
                </p>
                <button className=" text-primary-white bg-thumb md:bg-primary-white border-[0.5px] border-solid border-[#cacaca] py-1 px-2 md:py-4 md:px-6 text-xl md:text-h5 md:text-heading font-bold">
                  Request Renewal
                </button>
              </div> */}
                </div>
              ))}
              {sets.map((item) => (
                <div
                  key={item.id}
                  className="mb-4 p-4 border-1 border-primary-dark-10 bg-primary-white "
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <h3 className=" text-secondary-dark text-sm md:text-h4 text-montserrat font-semibold">
                        Order Id: <span className="uppercase">{getOrderIdOfSet(item.id)}</span>
                      </h3>
                      <p className="text-sm md:text-h5 text-secondary-light">
                        {moment(item.createdAt).format("DD MMMM, YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="my-3 flex gap-2 flex-row overflow-x-scroll">


                    <div className=" bg-neutral-0 justify-between rounded-[10px] lg:p-4 flex w-[223px] h-[96px] lg:w-[266px] lg:h-[134px]">
                      <div
                        style={{ background: item.backgroundColor }}
                        className="flex justify-center items-center  bg-neutral-50 w-[73px] p-2 pr-0 lg:p-0 lg:w-[80px] h-[96px] lg:h-[107px] mr-3 bg-[#A93030] "
                      >
                        {console.log(item.cover,'osdfjlksdjf')}
                        {item?.cover && (
                          <img
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${item?.cover}`}
                            alt="Cover photo"
                            className="h-full object-cover w-[55.5px] h-[77.8px] "
                          />
                        )}
                      </div>
                      <div className="flex flex-col w-[150px] py-[5px] lg:py-0 lg:w-[calc(100%-92px)] justify-between">
                        {/* <p className="text-secondary-light text-medium text-[14px] uppercase">
                          #law1223
                        </p> */}
                        <p className="text-left text-ellipsis overflow-hidden whitespace-nowrap w-[150px] text-heading-false font-semibold text-[16px] leading-[19px] lg:text-[15px] ">
                          {item?.title}
                        </p>
                        <p className="text-montserrat text-[14px] flex lg:text-[13px] font-semibold text-secondary-dark">

                          {/* {item["Bauthors.bookAuthorName"].map((auther, index, arr) => (
                                <span>
                                  {arr.indexOf(arr[index]) === arr.length - 1
                                    ? auther
                                    : (<span>{auther},&nbsp;</span>)}
                                </span>
                              ))} */}
                        </p>
                      </div>
                    </div>


                  </div>

                  <div className="text-right mb-2">
                    <button
                      onClick={() =>
                        navigate(`/order-history/order-details/${item.id}`)
                      }
                      className="underline text-tertiary-dark font-bold"
                    >
                      View Order
                    </button>
                  </div>
                  {/* <div className="flex justify-between items-end mb-0 md:mb-2">
                <p className="text-tertiary-dark font-semibold md:text-h5 text-xl">
                  LawLegat12 Publishers
                </p>
                <button className=" text-primary-white bg-thumb md:bg-primary-white border-[0.5px] border-solid border-[#cacaca] py-1 px-2 md:py-4 md:px-6 text-xl md:text-h5 md:text-heading font-bold">
                  Request Renewal
                </button>
              </div> */}
                </div>
              ))}
            </div>
            {/* <div className="flex justify-center bg-thumb text-primary-white px-1 py-2 mt-4 my-[6rem] md:hidden">
                 <p>
                   Add a new user
                 </p>
              </div> */}
          </div>
        </>
      )}
    </Page>
  );
}
