export const HOME = "/";
export const LOG_IN = "/login";
export const SIGN_UP = "/signup";
export const FORGOT_PASSWORD = "/forgot-password";
export const CREATE_PASSWORD = "/reset-password/:id";
export const PROFILE = "/profile";
export const BUSINESS_PROFILE = "/business-profile";
export const CART = "/cart";
export const NOTIFICATION = "/notification";
export const USER_ORDER_HISTORY = "/users/:order_history";
export const USER_ORDER_DETAILS = "/:order_history/:order_details";
export const ORDER_HISTORY = "/order-history";
export const ORDER_DETAILS = "/order-history/order-details/:id";
export const CATALOGUE = "/library";
export const SET = "/set";
export const SEARCH_BOOK = "/searchBook";
export const GENERATE_ACCESS_IDS = "/catalogue/generate-access-ids";
export const GENERATE_ORDER_IDS = "/catalogue/generate-order-ids";
export const EXPIRING_SOON = "/books/expiring-soon";
export const BOOK_DETAILS = "/books/:bookId";
export const SET_DETAILS = "/set/:bookId";
export const DEPARTMENTS = "/departments";
export const DEPARTMENT = "/departments/:id/:orgId";
export const SETTINGS = "/settings";
export const USERS = "/users";
export const ASSIGN_BOOKS = "/library/assign-books";
export const GRIEVANCES = "/grievances";

//--
export const USER_HISTORY = "/user/";
