import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  Page,
  Navbar,
  DialogBox,
  PopUp,
  BtnSmallWhite,
  Sidebar,
  BtnSmall,
  BtnSmallDisabled,
} from "../../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Member from "../../assets/images/member.png";
import Loader from "../../components/loader";
import {
  allOrganisationUser,
  addNewUser,
  addUserInBulk,
  allDepartements,
  downloadExcelfile,
} from "../../assets/Js/script";
import HeaderIcon from "../../components/Header_icon";
import { set, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

export default function Users() {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const notify = (message) => toast(message);

  const [modals, setModals] = useState({
    makePrimary: false,
    primary: false,
    deleteUser: false,
    newUser: false,
    uploadUserViaExcel: false,
    uploadUserManually: false,
    upload: false,
    successMsg: false,
    successMsgXl: false,
  });
  const [fileControls, setFileControls] = useState({
    pause: false,
    start: false,
    cancel: false,
    fail: false,
    completed: false,
  });
  const navigate = useNavigate();
  const [department, setDepartment] = useState(null);
  const [departmentData, setDepartmentData] = useState(null);
  const [viaExcelSheet, setViaExcelSheet] = useState(true);
  const [additionalFields, setAdditionalFields] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [depId, setDepId] = useState("");
  const [dropdown, setDropdown] = useState("Select Department");
  const [filterData, setFilterData] = useState("status");
  const [isLoading, setIsLoading] = useState(false);
  const [miniLoader, setMiniLoader] = useState(false);

  /////////////////////// api //////////////////

  useEffect(() => {
    async function getdepartments() {
      const { response, error } = await allDepartements();
      if (response.status === "success") {
        setDepartment([...response.data.departments]);
      }
    }
    getdepartments();
  }, []);
  async function getalluser() {
    const { response, error } = await allOrganisationUser(filterData);
    if (response.status === "success") {
      setIsLoading(false);
      setDepartmentData([...response.data.orgUsers]);
    }
  }
  useEffect(() => {
    setIsLoading(true);
    getalluser();
  }, [filterData]);

  function changeData(e) {
    const data = e.target.textContent;
    setFilterData(data);
  }

  ////////////////////////// api /////////////////
  async function handleNewMember(data) {
    if (data) {
      setIsLoading(true);
      const { response, error } = await addNewUser(
        {
          fullName: data.name,
          email: data.email,
          mobile: data.phone_number,
          designation: data.designation,
          departmentId: depId,

          // img:
        },
        additionalFields && { heading: data.category, text: data.heading }
      );
      if (response.status === "success") {
        setIsLoading(false)
        setModals((prev) => ({
          ...prev,
          successMsg: true,
          uploadUserManually: false
        }))
        setDropdown('Select Department')
        setDepId('')
        reset();
      } else {
        notify(response?.message ? response?.message : "Something went wrong");
      }
    }
  }

  async function downloadExcel() {
    const { response, errors } = downloadExcelfile();
  }
  async function handlefileget(e) {
    const [file] = e.target.files;
    setMiniLoader(true);
    const { response, error } = await addUserInBulk(file);
    // setMiniLoader(false);

    console.log(response, 'jjdjd');
    if (response?.status === "success") {
      setModals((prev) => ({
        ...prev,
        uploadUserViaExcel: false,
        successMsgXl: true,
      }));
      setMiniLoader(false);
    } else {
      setMiniLoader(false);
      notify(
        response?.status === "error"
          ? "Bulk User creation is faild check your email for further information"
          : "Something went wrong"
      );
    }
  }

  function handleDragover(e) {
    e.preventDefault();
  }
  function handleDrop(e) {
    e.preventDefault();
    setModals((prevState) => ({ ...prevState, upload: true }));

    const updateProgress = (e) => { };

    const oReq = new XMLHttpRequest();
    oReq.upload.addEventListener("progress", updateProgress);
    oReq.upload.addEventListener("load", (e) => {
      console.log(e);
    });
    oReq.upload.addEventListener("error", (e) => {
      console.log(e);
    });
    oReq.upload.addEventListener("abort", (e) => {
      console.log(e);
    });

    oReq.open();
  }

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setMiniLoader(true);

    const { response, error } = addUserInBulk(file);

    console.log(response, error);
    if (response.status === "success") {
      setModals((prev) => ({
        ...prev,
        uploadUserViaExcel: false,
      }));
      setMiniLoader(false);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
      setMiniLoader(false);
    }
  };


  console.log(departmentData, 'hjhj')
  return (
    <Page active="Users">
      <Navbar />
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <>
          <h1 className="hidden lg:block lg:text-heading font-semibold text-montserrat lg:text-h1 my-4 mb-2">
            Users List
          </h1>
          {/* headericon only for mobile screen */}
          <HeaderIcon heading={"Users"} sort={true} />
          <div className="hidden md:flex justify-between relative">
            <p className="text-secondary-light font-semibold text-h5 text-montserrat">
              View &#38; Manage Users in your Organisation
            </p>
            <div className="flex ">
              <label className="hidden lg:block text-secondary-light font-semibold text-p1 text-montserrat mr-3">
                Sort by
              </label>
              <div className="flex flex-col relative group">
                <button
                  // onClick={() => setFilterSort(true)}
                  className="flex capitalize justify-center gap-6 items-center bg-neutral-10 w-[141px] h-8 text-secondary-dark text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold"
                >
                  {filterData}
                  {/* <p>{filterData?  filterData : 'Validity'}{" "} Year</p> */}
                  <span className="ml-2">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="18" height="15" rx="2" fill="#FFD600" />
                      <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                    </svg>
                  </span>
                </button>
                {/* ${flterSort ? "flex" : "hidden"} */}
                <div
                  className={`
                         hidden group-hover:flex flex-col absolute z-10 top-[33px] left-0 bg-primary-white w-[141px] h-auto`}
                >
                  <div>
                    <span
                      onClick={changeData}
                      className="w-full bg-[#e6e7e826] capitalize pl-4 cursor-pointer text-secondary-light p-2 hover:bg-[#f0f0f0] text-left h-8 flex items-center text-p1"
                    >
                      pending
                    </span>
                  </div>
                  <span
                    onClick={changeData}
                    className="w-full bg-[#e6e7e826] capitalize pl-4 cursor-pointer text-secondary-light p-2 hover:bg-[#f0f0f0] text-left h-8 flex items-center text-p1"
                  >
                    active
                  </span>
                  <span
                    onClick={changeData}
                    className="w-full bg-[#e6e7e826] capitalize pl-4 cursor-pointer text-secondary-light p-2 hover:bg-[#f0f0f0] text-left h-8 flex items-center text-p1"
                  >
                    disabled
                  </span>
                </div>
              </div>
              <button
                onClick={() =>
                  setModals((prevState) => ({ ...prevState, newUser: true }))
                }
                className="ml-14 underline text-montserrat font-semibold text-[18px] text-tertiary-dark"
              >
                Add a New User
              </button>
            </div>
          </div>
          <div className="my-4 mb-[58px] lg:my-[58px] flex justify-center md:justify-start flex-wrap gap-[22px]">
            {departmentData?.length > 0 ? (
              departmentData?.map((card, index) => (
                <div
                  key={card}
                  onClick={() => navigate(`/users/order-history=${card.id}`)}
                  className="flex flex-col lg:flex-row  max-w-[330px] lg:max-w-none min-w-[305px] w-[100%] relative justify-center lg:justify-start lg:gap-2 lg:items-center border-1 lg:mb-2 lg:ml-2 border-solid border-primary-dark-20 lg:w-card h-[168px]"
                >
                  <div
                    className={`w-14 left-2 hidden self-start lg:flex m-2 items-center justify-center h-14 rounded-full ${card.photo == null && "bg-primary-dark"
                      } `}
                  >
                    {card.photo ? (
                      <img src={card.photo} alt="img" />
                    ) : (
                      <span
                        className={`text-[25px] uppercase font-bold text-[#fff]`}
                      >
                        {card.fullName[0]}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="ml-[70px] lg:ml-0 flex items-center">
                      <h4 className="mb-2 capitalize lg:mb-3 flex items-center text-secondary-dark font-semibold text-h5 mr-4">
                        {card.fullName}
                        <button
                          className={`${card.status === "pending"
                            ? "bg-[#F4D429] "
                            : card.status === "active"
                              ? "bg-primary-dark"
                              : card.status === "disable"
                                ? "bg-[#AEAEAE]"
                                : "bg-[#fff]"
                            } ml-4 py-[2px] px-[34px] text-montserrat font-semibold capitalize text-tablet-p1 text-primary-white `}
                        >
                          {card.status}
                        </button>
                      </h4>
                      {modals.makePrimary && (
                        <p className="flex items-center text-p1 font-semibold text-tertiary-dark ">
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 8.5C0 6.24566 0.895533 4.08365 2.48959 2.48959C4.08365 0.895533 6.24566 0 8.5 0C10.7543 0 12.9163 0.895533 14.5104 2.48959C16.1045 4.08365 17 6.24566 17 8.5C17 10.7543 16.1045 12.9163 14.5104 14.5104C12.9163 16.1045 10.7543 17 8.5 17C6.24566 17 4.08365 16.1045 2.48959 14.5104C0.895533 12.9163 0 10.7543 0 8.5H0ZM8.01493 12.138L12.9087 6.02027L12.0247 5.31307L7.85173 10.5275L4.896 8.0648L4.17067 8.9352L8.01493 12.1391V12.138Z"
                              fill="#FFD600"
                            />
                          </svg>
                          Primary Contact
                        </p>
                      )}
                    </div>
                    <div className="hidden lg:flex items-center mt-4 lg:mt-2">
                      <p className="text-p1 text-secondary-light mr-11">
                        Dept. name:
                      </p>
                      <p className="text-p1 font-semibold w-[160px] capitalize overflow-hidden whitespace-nowrap text-ellipsis text-secondary-dark">

                        {card.Departments.length === 0 && "Not in any Department "}
                        {card.Departments.length > 1 ? card.Departments?.map((elm, i, arr) => (<span>{elm.departmentName},&nbsp; </span>)) : card.Departments?.map((elm, i, arr) => `${elm.departmentName}`)}
                      </p>
                    </div>
                    <div className="hidden lg:flex items-center">
                      <p className="text-p1 text-secondary-light mr-6">
                        E-mail address:
                      </p>
                      <p className="text-p1 font-semibold text-secondary-dark ">
                        {card.email}
                      </p>
                    </div>
                    <div className="hidden lg:flex items-center ">
                      <p className="text-p1 text-secondary-light mr-6">
                        Phone number:
                      </p>
                      <p className="text-p1 font-semibold text-secondary-dark">
                        +91 {card.mobile}
                      </p>
                    </div>
                  </div>
                  {/* this div is for mobile version \|/ */}
                  <div className="ml-[10px] flex lg:hidden flex-col">
                    <div className="flex items-center mt-4 lg:mt-2">
                      <p className="text-p1 text-secondary-light mr-11">
                        Dept. name:
                      </p>
                      <p className="text-p1 font-semibold w-[140px] capitalize overflow-hidden whitespace-nowrap text-ellipsis text-secondary-dark">
                        {card.Departments?.map((elm, i) => (`${elm.departmentName}, `))}
                        {card.Departments.length === 0 && "Not in any Department "}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-p1 text-secondary-light mr-6">
                        E-mail address:
                      </p>
                      <p className="text-p1 font-semibold text-secondary-dark">
                        {card.email}
                      </p>
                    </div>
                    <div className="flex items-center ">
                      <p className="text-p1 text-secondary-light mr-6">
                        Phone number:
                      </p>
                      <p className="text-p1 font-semibold text-secondary-dark">
                        +91 {card.mobile}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      setModals((prevState) => ({
                        ...prevState,
                        deleteUser: true,
                      }));
                    }}
                    className="absolute top-4 lg:top-6 right-3 lg:right-4"
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.61927 9.65676C9.38022 9.87286 9.056 9.99427 8.71794 9.99427C8.37988 9.99427 8.05566 9.87286 7.8166 9.65676L5.00053 6.74689L2.18446 9.6558C2.06645 9.76424 1.92585 9.85049 1.77078 9.90956C1.61572 9.96864 1.44925 9.99937 1.28097 9.99999C1.1127 10.0006 0.945962 9.9711 0.790368 9.91317C0.634774 9.85524 0.493406 9.77003 0.374419 9.66246C0.255433 9.55489 0.161182 9.42709 0.097104 9.28642C0.0330256 9.14575 0.000387576 8.99501 0.00107276 8.84288C0.00175794 8.69075 0.0357526 8.54026 0.101096 8.40007C0.166439 8.25988 0.261838 8.13277 0.38179 8.02608L3.31153 5.00096L0.380728 1.97392C0.260776 1.86723 0.165377 1.74012 0.100034 1.59993C0.0346904 1.45974 0.000695752 1.30925 1.05685e-05 1.15712C-0.000674615 1.00499 0.0319635 0.854247 0.0960419 0.71358C0.16012 0.572914 0.254371 0.445109 0.373357 0.337538C0.492344 0.229967 0.633711 0.144758 0.789306 0.0868277C0.9449 0.0288969 1.11164 -0.000609893 1.27991 9.55452e-06C1.44818 0.000629002 1.61465 0.0313623 1.76972 0.0904366C1.92479 0.149511 2.06538 0.235758 2.1834 0.344201L5.00053 3.25504L7.8166 0.344201C7.93462 0.235758 8.07521 0.149511 8.23028 0.0904366C8.38535 0.0313623 8.55182 0.000629002 8.72009 9.55452e-06C8.88836 -0.000609893 9.0551 0.0288969 9.21069 0.0868277C9.36629 0.144758 9.50766 0.229967 9.62664 0.337538C9.74563 0.445109 9.83988 0.572914 9.90396 0.71358C9.96804 0.854247 10.0007 1.00499 9.99999 1.15712C9.9993 1.30925 9.96531 1.45974 9.89997 1.59993C9.83462 1.74012 9.73922 1.86723 9.61927 1.97392L6.68954 5.00096L9.61927 8.02608C9.73779 8.13311 9.83181 8.26021 9.89596 8.40011C9.96011 8.54001 9.99313 8.68997 9.99313 8.84142C9.99313 8.99287 9.96011 9.14283 9.89596 9.28273C9.83181 9.42263 9.73779 9.54973 9.61927 9.65676Z"
                        fill="black"
                        fill-opacity="0.35"
                      />
                    </svg>
                  </button>

                  <div
                    className={`w-14 lg:hidden absolute top-2  left-2 flex items-center justify-center h-14 rounded-full ${card.photo == null && "bg-primary-dark"
                      } `}
                  >
                    {card.photo ? (
                      <img src={card.photo} alt="img" />
                    ) : (
                      <span
                        className={`text-[25px] uppercase font-bold text-[#fff]`}
                      >
                        {card.fullName[0]}
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center w-full h-[70%]">
                <p className="mt-[170px] text-secondary-light text-montserrat text-center font-semibold leading-[29px] text-h3">
                  Do not have any organisation user
                </p>
              </div>
            )}
          </div>
          <div
            className="flex justify-center bg-thumb text-primary-white px-1 py-2 mt-4 my-[6rem] md:hidden"
            onClick={() =>
              setModals((prev) => ({ ...prev, uploadUserManually: true }))
            }
          >
            <p>Add a new user</p>
          </div>

          {/*-------- popups ------------- */}

          {modals.deleteUser && (
            <DialogBox show={modals.deleteUser}>
              <div className="bg-primary-white flex flex-col justify-between relative w-full h-[463px] lg:w-pop-up lg:h-pop-up p-8">
                <h4 className="text-h4 text-tertiary-dark font-bold">
                  User Removed Successfully!
                </h4>
                <h5 className="text-heading font-bold">
                  Luke Elliot has been removed from you team
                </h5>
                <p className="text-secondary-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                  utvest-ibulum bibendum egestas. Enim mi arcu convallis enim
                  purus interdum. Ut neque at adipiscing torotr facilisis
                  asgittis levero. Ac bibendum vel blandit.
                </p>
                <div className="text-center lg:text-right">
                  <button
                    onClick={() => {
                      setModals((prevState) => ({
                        ...prevState,
                        deleteUser: false,
                      }));
                    }}
                    className="py-4 px-3 min-w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
                  >
                    Back to Users
                  </button>
                </div>
                <button
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      deleteUser: false,
                    }));
                    reset();
                  }}
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
          {modals.newUser && (
            <DialogBox show={modals.newUser}>
              <div className={` bg-primary-white flex flex-col  relative  p-8`}>
                <h4 className="text-h4 text-tertiary-dark font-bold mb-4">
                  Add New User(s)
                </h4>

                <p className="text-secondary-light mb-[26px] w-[463px]">
                  Proceed by selecting one of the following options
                </p>
                <div className="flex items-center mb-2">
                  <input
                    id="org"
                    type="radio"
                    checked={viaExcelSheet}
                    onChange={() => setViaExcelSheet(true)}
                    name="organization"
                    className="hidden"
                  />
                  <div
                    onClick={() => setViaExcelSheet(true)}
                    className={`${viaExcelSheet ? "bg-primary-dark" : "bg-neutral-50"
                      } w-[14px] h-[14px] cursor-pointer rounded-full`}
                  ></div>
                  <label
                    htmlFor="org"
                    className="font-semibold cursor-pointer text-heading text-h5 ml-3"
                  >
                    Bulk Upload from Excel sheet{" "}
                    <span class="text-secondary-light">(.xlsx format only)</span>
                  </label>
                </div>
                <div className="flex items-center mb-[22px]">
                  <input
                    checked={!viaExcelSheet}
                    onChange={() => setViaExcelSheet(false)}
                    id="tps"
                    type="radio"
                    name="tps"
                    className="hidden"
                  />
                  <div
                    onClick={() => setViaExcelSheet(false)}
                    className={`${!viaExcelSheet ? "bg-primary-dark" : "bg-neutral-50"
                      } w-[14px] cursor-pointer h-[14px] rounded-full`}
                  ></div>
                  <label
                    htmlFor="tps"
                    className="font-semibold cursor-pointer text-heading text-h5 ml-3"
                  >
                    Add One User
                  </label>
                </div>
                <div className="text-center lg:text-right">
                  <button
                    onClick={() => {
                      setModals((prevState) => ({
                        ...prevState,
                        uploadUserViaExcel: viaExcelSheet ? true : false,
                        uploadUserManually: !viaExcelSheet ? true : false,
                        newUser: false,
                      }));
                    }}
                    className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
                  >
                    Continue
                  </button>
                </div>
                <button
                  onClick={() =>
                    setModals((prevState) => ({ ...prevState, newUser: false }))
                  }
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
          {modals.uploadUserManually && (
            <div className="fixed w-full h-full bg-[rgba(53,14,46,0.45)] top-0 left-0 flex justify-center items-center">
              <div
                className={` bg-primary-white flex flex-col h-[95%] relative p-4 pt-8 lg:p-8`}
              >
                <h4 className="flex items-center text-h4 text-tertiary-dark font-bold mb-2">
                  <button
                    onClick={() => navigate(-1)}
                    className="mr-2 lg:hidden"
                  >
                    <svg
                      width="12"
                      height="20"
                      viewBox="0 0 12 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                        fill="#702963"
                      />
                    </svg>
                  </button>
                  Add a New User
                </h4>

                <h3 className="my-[15px] font-semibold text-heading-false">
                  Enter User Details
                </h3>

                <p className="text-secondary-light mb-[20px] lg:w-[466px]">
                  Enter all required details to successfully add a user to your
                  Organisation
                </p>
                <form
                  onSubmit={handleSubmit(handleNewMember)}
                  className="flex relative mt-4 flex-col justify-between h-[70%] "
                >
                  <div className="relative flex flex-col overflow-hidden overflow-y-scroll scrollbar scrollbar-thumb-thumb scrollbar-track-track h-[85%] ">
                    {/* <div className="lg:hidden flex items-center justify-center w-[100%]"> 
                // <div className="flex items-center justify-center bg-neutral-10 rounded-full w-[144px] h-[144px]">
                //   <p className="text-secondary-light text-center">
                //     Add a Profile <br /> Picture
                //   </p>
                // </div> 
              </div> */}
                    <>
                      <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Name*
                      </label>
                      <input
                        type="name"
                        name="name"
                        placeholder="Enter User Name"
                        className="pl-2 mt-4 mb-3 outline-primary-light font-semibold lg:w-40 h-12 py-[14px]   bg-primary-white border-1 border-primary-light border-solid"
                        {...register("name", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-14px] text-[13px] text-[red]">
                        {errors.name && "Please enter the Full Name!"}
                      </p>
                    </>

                    <>
                      <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                        Email Address*
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Email Address"
                        className="pl-2  mt-4 mb-3 outline-primary-light font-semibold lg:w-[221px] h-12 py-[14px] bg-primary-white border-1 border-primary-light border-solid"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />
                      <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-14px] text-[13px] text-[red]">
                        {errors.email && "Please enter Valid Email Address!"}
                      </p>
                    </>
                    <>
                      <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                        Mobile Number*
                      </label>
                      <input
                        type="tel"
                        name="email"
                        placeholder="Enter Mobile Number"
                        className="pl-2  mt-4 mb-3 outline-primary-light py-[14px] font-semibold lg:w-[221px] h-12 bg-primary-white border-1 border-primary-light border-solid"
                        {...register("phone_number", {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                        })}
                      />
                      <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-14px] text-[13px] text-[red]">
                        {errors.phone_number &&
                          "Please enter Valid Mobile Number!"}
                      </p>
                    </>
                    <>
                      <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                        Designation*
                      </label>
                      <input
                        type="text"
                        name="designation"
                        placeholder="Enter Designation"
                        className="pl-2  mt-4 mb-3 outline-primary-light py-[14px] font-semibold lg:w-[221px] h-12 bg-primary-white border-1 border-primary-light border-solid"
                        {...register("designation", {
                          required: true,
                          maxLength: 50,
                        })}
                      />
                      <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-14px] text-[13px] text-[red]">
                        {errors.designation && "Please enter Your Full Name !"}
                      </p>
                    </>
                    <>
                      <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-2">
                        Department
                      </label>
                      <div className="relative z-40 cursor-pointer border-1 border-solid border-[#eee] lg:w-[221px] max-h-12 h-fit px-[15px] py-2 flex justify-between items-center">
                        <p
                          onClick={() => setDropdownActive(true)}
                          className="font-semibold capitalize text-p1 text-secondary-light"
                        >
                          {dropdown}
                        </p>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setDropdownActive(!dropdownActive);
                          }}
                        >
                          <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.608 0.167725C11.4989 0.167725 11.9451 1.24487 11.3151 1.87483L6.71883 6.47112C6.3283 6.86164 5.69514 6.86164 5.30461 6.47112L0.708328 1.87483C0.0783629 1.24487 0.524529 0.167725 1.41543 0.167725L10.608 0.167725Z"
                              fill="#737272"
                            />
                          </svg>
                        </button>
                        {dropdownActive && (
                          <div className="absolute top-[48px] bg-primary-white left-0 w-full shadow-[0px_3px_3px_2px_rgba(135,133,133,0.13)] h-[173px] overflow-y-scroll ">
                            {department?.map((dep, i) => (
                              <button
                                key={i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setDropdown(dep.departmentName);
                                  setDepId(dep.id);
                                  setDropdownActive(false);
                                }}
                                className="capitalize w-full text-left p-4 hover:bg-[#f0f0f0] text-secondary-light font-semibold"
                              >
                                {dep.departmentName}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </>

                    {additionalFields && (
                      <div className="relative flex flex-col">
                        <input
                          className="w-[200px] text-h5 text-montserrat mt-4 font-semibold text-secondary-dark mb-0"
                          type="text"
                          placeholder="Enter Custom Field Label"
                          name="category"
                          {...register("category", {
                            maxLength: 30,
                          })}
                        />
                        {/* <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-2px] text-[13px] text-[red]">
                    {errors.category && `Please enter Custom Field Label!`}
                  </p> */}
                        <div className="flex flex-row items-center lg:block">
                          <input
                            type="text"
                            name={`heading`}
                            placeholder="Enter Details here"
                            className="pl-2  mt-4 mb-3 outline-primary-light py-[14px] font-semibold lg:w-[221px] h-12 bg-primary-white border-1 border-[#eee] border-solid"
                            {...register(`heading`, {
                              maxLength: 80,
                            })}
                          />
                          {/* <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-14px] text-[13px] text-[red]">
                      {errors.heading && `Please enter Custom Field value!`}
                    </p> */}
                          <button
                            onClick={() => setAdditionalFields(false)}
                            className="flex justify-center ml-2 lg:ml-0 items-center bg-[#ebe8e8] w-[34px] h-[34px] rounded-full lg:absolute bottom-5 left-64"
                          >
                            <svg
                              width="18"
                              height="21"
                              viewBox="0 0 18 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.8 3.7998C10.8 3.32241 10.6104 2.86458 10.2728 2.52701C9.93523 2.18945 9.47739 1.9998 9 1.9998C8.52261 1.9998 8.06477 2.18945 7.72721 2.52701C7.38964 2.86458 7.2 3.32241 7.2 3.7998H6C6 3.00416 6.31607 2.24109 6.87868 1.67848C7.44129 1.11588 8.20435 0.799805 9 0.799805C9.79565 0.799805 10.5587 1.11588 11.1213 1.67848C11.6839 2.24109 12 3.00416 12 3.7998H17.4C17.5591 3.7998 17.7117 3.86302 17.8243 3.97554C17.9368 4.08806 18 4.24067 18 4.3998C18 4.55893 17.9368 4.71155 17.8243 4.82407C17.7117 4.93659 17.5591 4.9998 17.4 4.9998H16.7352L15.18 18.4758C15.1123 19.0607 14.8319 19.6003 14.3923 19.9919C13.9527 20.3835 13.3844 20.5999 12.7956 20.5998H5.2044C4.61562 20.5999 4.04735 20.3835 3.6077 19.9919C3.16806 19.6003 2.88771 19.0607 2.82 18.4758L1.2636 4.9998H0.6C0.459569 4.99985 0.323571 4.95064 0.215689 4.86074C0.107808 4.77084 0.0348774 4.64594 0.00959988 4.5078L0 4.3998C0 4.24067 0.0632142 4.08806 0.175736 3.97554C0.288258 3.86302 0.44087 3.7998 0.6 3.7998H10.8ZM15.5256 4.9998H2.4732L4.0116 18.3378C4.04547 18.6303 4.18573 18.9002 4.40568 19.096C4.62562 19.2919 4.90991 19.4 5.2044 19.3998H12.7956C13.0899 19.3997 13.3739 19.2915 13.5936 19.0956C13.8133 18.8998 13.9534 18.6301 13.9872 18.3378L15.5256 4.9998ZM7.2 7.9998C7.494 7.9998 7.74 8.1858 7.7904 8.4306L7.8 8.5254V15.8754C7.8 16.1646 7.5312 16.3998 7.2 16.3998C6.906 16.3998 6.66 16.2138 6.6096 15.969L6.6 15.8742V8.5266C6.6 8.2362 6.8688 8.001 7.2 8.001V7.9998ZM10.8 7.9998C11.094 7.9998 11.34 8.1858 11.3904 8.4306L11.4 8.5254V15.8754C11.4 16.1646 11.1312 16.3998 10.8 16.3998C10.506 16.3998 10.26 16.2138 10.2096 15.969L10.2 15.8742V8.5266C10.2 8.2362 10.4688 8.001 10.8 8.001V7.9998Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                    <button
                      onClick={() => setAdditionalFields(true)}
                      type="button"
                      className="text-secondary-light mt-4 relative cursor-pointer border-1 border-solid border-[#eee] w-[221px] h-12 px-[15px] py-2 flex justify-between items-center"
                    >
                      <span>
                        <svg
                          width="30"
                          height="31"
                          viewBox="0 0 30 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6964 0.125C10.8463 0.172817 7.1669 1.76852 4.44423 4.57127C1.72156 7.37402 0.171451 11.1616 0.125 15.125C0.171451 19.0884 1.72156 22.876 4.44423 25.6787C7.1669 28.4815 10.8463 30.0772 14.6964 30.125C18.5466 30.0772 22.226 28.4815 24.9486 25.6787C27.6713 22.876 29.2214 19.0884 29.2679 15.125C29.2214 11.1616 27.6713 7.37402 24.9486 4.57127C22.226 1.76852 18.5466 0.172817 14.6964 0.125ZM23.023 16.1964H15.7372V23.6964H13.6556V16.1964H6.3699V14.0536H13.6556V6.55357H15.7372V14.0536H23.023V16.1964Z"
                            fill="#EBE8E8"
                          />
                        </svg>
                      </span>
                      Add Additional field
                    </button>
                  </div>

                  <div className="mt-8 text-right flex justify-end items-center">
                    <BtnSmallWhite
                      onClick={() => {
                        setModals((prevState) => ({
                          ...prevState,
                          uploadUserManually: false,
                        }));
                        setDropdown('Select Department')
                        setDepId('')
                      }
                      }
                    // style={additionalFields && { flex: ".5" }}
                    >
                      Cancel
                    </BtnSmallWhite>
                    <span className="mx-2"></span>
                    {!isValid ? (
                      <BtnSmallDisabled>Add User</BtnSmallDisabled>
                    ) : (
                      <BtnSmall>Add User</BtnSmall>
                    )}
                  </div>
                  {/* {
                <div className="hidden absolute top-8 right-12 lg:flex items-center justify-center bg-neutral-10 rounded-full w-[144px] h-[144px]">
                  <p className="text-secondary-light text-center">
                    Add a profile <br /> picture
                  </p>
                </div>
              } */}
                  {/* <div className="text-right flex items-center">
                <BtnSmallWhite
                  onClick={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      newMember: false,
                    }))
                  }
                >
                  Cancel
                </BtnSmallWhite>
                {addNewMemberDisabled ? (
                  <BtnSmallDisabled disabled={addNewMemberDisabled}>
                    Save
                  </BtnSmallDisabled>
                ) : (
                  <BtnSmall>Save</BtnSmall>
                )}
              </div> */}
                </form>

                <button
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      uploadUserManually: false,
                    }));
                    reset();
                    setDropdown('Select Department')
                        setDepId('')
                  }}
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
          {modals.uploadUserViaExcel && (
            <DialogBox show={modals.uploadUserViaExcel}>
              <div className={` bg-primary-white flex flex-col  relative  p-8`}>
                <h4 className="text-h4 text-tertiary-dark font-bold mb-2">
                  Bulk Upload Users
                </h4>

                <h3 className="my-[15px] font-semibold text-heading-false">
                  Upload Bulk Users via Excel{" "}
                  <span class="text-secondary-light">(.xlsx format only)</span>
                </h3>

                <p className="text-secondary-light mb-[20px] w-[466px]">
                  Follow the following steps to successfully add users in bulk
                  in your organisation
                </p>

                <p className="mb-1 text-secondary-dark">
                  Step 1: Download the sample Excel Sheet
                  <span> </span>
                  <span
                    onClick={downloadExcel}
                    className="underline text-tertiary-dark cursor-pointer"
                  >
                    Click Here to Download
                  </span>
                </p>
                <p className="mb-2 text-secondary-dark">
                  Step 2: Upload the File
                </p>
                <div
                  // onDragOver={handleDragover}
                  // onDrop={handleDrop}

                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                  className="h-28 mb-3 bg-[#F3EBF2] border-[1.5px] border-dashed border-[rgba(112,41,99,0.35)] flex items-center justify-center"
                >
                  {miniLoader ? (
                    <div>
                      <div className="flex items-center justify-center flex-col">
                        <svg
                          className="animate-spin ml-1 mr-3 h-8 w-8 text-[#702963]"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-20"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>

                      <h3 className="font-[500] text-center text-heading text-p mb-3 mt-2">
                        Upload in Progress...
                      </h3>
                      {/* <p className="text-secondary-light text-center text-p1">
                  Please wait while the book gets uploaded
                </p> */}
                    </div>
                  ) : (
                    <div>
                      <svg
                        className="mx-auto"
                        width="34"
                        height="30"
                        viewBox="0 0 34 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.280487 29.1581C0.596672 29.6831 1.12875 30 1.69992 30H27.1987C27.8787 30 28.4941 29.5537 28.761 28.8637L33.8607 15.7387C33.9725 15.4536 34.0181 15.1423 33.9935 14.8329C33.9689 14.5234 33.8749 14.2256 33.7199 13.9663C33.5649 13.707 33.3538 13.4943 33.1056 13.3474C32.8575 13.2005 32.5801 13.1241 32.2985 13.125H30.5986V7.5C30.5986 5.43188 29.0737 3.75 27.1987 3.75H15.8858L11.5408 0H3.39984C1.52483 0 0 1.68187 0 3.75V28.125H0.0118994C0.00811046 28.4909 0.101476 28.85 0.280487 29.1581ZM27.1987 7.5V13.125H6.79968C6.11971 13.125 5.50434 13.5712 5.23746 14.2613L3.39984 18.9919V7.5H27.1987Z"
                          fill="#DAC5D7"
                        />
                      </svg>

                      <p className="mt-[14px] font-[500] text-secondary-dark text-tablet-p1">
                        Drag &#38; Drop file here or{" "}
                        <label
                          htmlFor="file_upload"
                          className="underline cursor-pointer text-tertiary-dark"
                        >
                          Browse
                        </label>
                      </p>
                      <p class="text-secondary-light text-tablet-p1 text-center">
                        Only .xlsx format allowed
                      </p>
                      <input
                        id="file_upload"
                        type="file"
                        className=" hidden"
                        onChange={(e) => handlefileget(e)}
                      />

                      {/* accept="application/vnd.ms-excel" */}
                    </div>
                  )}
                </div>
                {/* <div className="h-[85px] relative flex flex-col justify-between py-2 px-4 border border-1 border-[rgba(202,202,202,0.44)] bg-[rgba(196,196,196,0.1)] mt-4 mb-8">
              <p className="text-heading-false font-[500] text-tablet-p1">
                Uploading...
              </p>
              <p className="text-p1 text-secondary-light">
                50% <span className="ml-3">19 seconds remaining</span>
              </p>
              <div className="bg-[#e5e5e5] h-[11px] w-full">
                <div className="bg-primary-light h-full w-1/2"></div>
              </div>
              {fileControls.completed ? (
                <button className="absolute top-[11px] right-4">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 13.5484C0 9.95513 1.475 6.50905 4.1005 3.96823C6.72601 1.42742 10.287 0 14 0C17.713 0 21.274 1.42742 23.8995 3.96823C26.525 6.50905 28 9.95513 28 13.5484C28 17.1416 26.525 20.5877 23.8995 23.1285C21.274 25.6694 17.713 27.0968 14 27.0968C10.287 27.0968 6.72601 25.6694 4.1005 23.1285C1.475 20.5877 0 17.1416 0 13.5484H0ZM13.2011 19.3471L21.2613 9.59587L19.8053 8.46865L12.9323 16.7801L8.064 12.8547L6.86933 14.2421L13.2011 19.3489V19.3471Z"
                      fill="#702963"
                    />
                  </svg>
                </button>
              ) : fileControls.cancel ? (
                <button className="absolute top-[11px] right-4">
                  <svg
                    width="29"
                    height="27"
                    viewBox="0 0 29 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 26.7065C6.7678 26.7065 0.5 20.8074 0.5 13.53C0.5 6.25262 6.7678 0.353516 14.5 0.353516C22.2322 0.353516 28.5 6.25262 28.5 13.53C28.4916 20.8034 22.228 26.6986 14.5 26.7065ZM13.1 17.4829V20.1182H15.9V17.4829H13.1ZM13.1 6.94175V14.8476H15.9V6.94175H13.1Z"
                      fill="#F28252"
                    />
                  </svg>
                </button>
              ) : fileControls.fail ? (
                <>
                  <button className="bg-[#c5c1c4] py-[6.5px] px-[7px] rounded-full absolute top-[11px] right-14">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.0563 2.87445L13.08 3.59973C11.7487 1.98307 9.67819 0.945312 7.35321 0.945312C3.33861 0.945312 0.0891841 4.02898 0.0839906 7.84449C0.078797 11.6633 3.33515 14.7601 7.35321 14.7601C10.4918 14.7601 13.1665 12.8688 14.1844 10.2193C14.2104 10.1503 14.1723 10.073 14.0996 10.0499L13.118 9.72923C13.0838 9.71808 13.0464 9.72011 13.0137 9.73486C12.981 9.74961 12.9557 9.77592 12.9432 9.80817C12.912 9.8904 12.8774 9.97263 12.8411 10.0532C12.5416 10.7275 12.1122 11.3327 11.5652 11.8524C11.0225 12.3689 10.3801 12.781 9.67299 13.0662C8.9407 13.3606 8.15994 13.5102 7.35667 13.5102C6.55168 13.5102 5.77265 13.3606 5.04036 13.0662C4.33261 12.7822 3.6899 12.37 3.14818 11.8524C2.60407 11.337 2.17072 10.7259 1.8723 10.0532C1.56242 9.3559 1.40488 8.61582 1.40488 7.85107C1.40488 7.08632 1.56242 6.34625 1.8723 5.64893C2.17179 4.97463 2.60113 4.36941 3.14818 3.84971C3.69523 3.33001 4.3323 2.92215 5.04036 2.63598C5.77265 2.3416 6.55341 2.19193 7.35667 2.19193C8.16167 2.19193 8.9407 2.3416 9.67299 2.63598C10.3807 2.91992 11.0235 3.33218 11.5652 3.84971C11.7366 4.01253 11.8976 4.18521 12.0464 4.36612L11.0043 5.13909C10.9836 5.15425 10.9679 5.17462 10.959 5.19788C10.95 5.22113 10.9481 5.24632 10.9535 5.27054C10.959 5.29476 10.9715 5.31704 10.9896 5.33481C11.0078 5.35258 11.0309 5.36512 11.0562 5.37099L14.0962 6.07817C14.1827 6.09791 14.2675 6.03541 14.2675 5.95154L14.2814 2.97642C14.2797 2.86787 14.1464 2.80702 14.0563 2.87445Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button className="absolute top-[11px] right-4">
                    <svg
                      width="29"
                      height="27"
                      viewBox="0 0 29 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 26.7065C6.7678 26.7065 0.5 20.8074 0.5 13.53C0.5 6.25262 6.7678 0.353516 14.5 0.353516C22.2322 0.353516 28.5 6.25262 28.5 13.53C28.4916 20.8034 22.228 26.6986 14.5 26.7065ZM13.1 17.4829V20.1182H15.9V17.4829H13.1ZM13.1 6.94175V14.8476H15.9V6.94175H13.1Z"
                        fill="#F28252"
                      />
                    </svg>
                  </button>
                </>
              ) : fileControls.pause ? (
                <button className=" bg-[#c5c1c4] py-[7px] rounded-full pl-[10px] pr-[6.5px] absolute top-[11px] right-14">
                  <svg
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.931641 12.2044L10.0538 6.29532L0.931641 0.38623V12.2044Z"
                      fill="white"
                    />
                  </svg>
                </button>
              ) : (
                <button className="absolute top-[11px] right-14">
                  <svg
                    width="29"
                    height="27"
                    viewBox="0 0 29 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.166 0C11.3971 0 8.69032 0.77787 6.38804 2.23524C4.08575 3.69262 2.29133 5.76404 1.23171 8.18756C0.172082 10.6111 -0.105165 13.2779 0.435028 15.8507C0.975221 18.4235 2.30859 20.7867 4.26653 22.6416C6.22446 24.4965 8.71902 25.7597 11.4348 26.2715C14.1505 26.7832 16.9654 26.5206 19.5236 25.5167C22.0818 24.5129 24.2682 22.8129 25.8066 20.6318C27.3449 18.4507 28.166 15.8864 28.166 13.2632C28.166 11.5214 27.8039 9.79672 27.1003 8.18756C26.3968 6.5784 25.3655 5.11629 24.0655 3.88469C22.7655 2.65309 21.2221 1.67613 19.5236 1.0096C17.825 0.343062 16.0045 0 14.166 0ZM11.366 17.2421C11.366 17.5939 11.2185 17.9312 10.956 18.1799C10.6934 18.4287 10.3373 18.5684 9.96602 18.5684C9.59472 18.5684 9.23862 18.4287 8.97607 18.1799C8.71352 17.9312 8.56602 17.5939 8.56602 17.2421V9.28421C8.56602 8.93245 8.71352 8.59509 8.97607 8.34636C9.23862 8.09763 9.59472 7.95789 9.96602 7.95789C10.3373 7.95789 10.6934 8.09763 10.956 8.34636C11.2185 8.59509 11.366 8.93245 11.366 9.28421V17.2421ZM19.766 17.2421C19.766 17.5939 19.6185 17.9312 19.356 18.1799C19.0934 18.4287 18.7373 18.5684 18.366 18.5684C17.9947 18.5684 17.6386 18.4287 17.3761 18.1799C17.1135 17.9312 16.966 17.5939 16.966 17.2421V9.28421C16.966 8.93245 17.1135 8.59509 17.3761 8.34636C17.6386 8.09763 17.9947 7.95789 18.366 7.95789C18.7373 7.95789 19.0934 8.09763 19.356 8.34636C19.6185 8.59509 19.766 8.93245 19.766 9.28421V17.2421Z"
                      fill="#C5C1C4"
                    />
                  </svg>
                </button>
              )}
              <button className="px-[9px] py-[7px] rounded-full bg-[rgba(242,130,82,0.24)] absolute top-[11px] right-4">
                <svg
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5812 12.5313C10.3182 12.7899 9.9616 12.9351 9.58973 12.9351C9.21786 12.9351 8.86122 12.7899 8.59826 12.5313L5.50058 9.04934L2.4029 12.5301C2.27309 12.6599 2.11843 12.7631 1.94786 12.8338C1.77729 12.9045 1.59417 12.9412 1.40907 12.942C1.22397 12.9427 1.04056 12.9074 0.869405 12.8381C0.698251 12.7688 0.542747 12.6668 0.411861 12.5381C0.280976 12.4094 0.177301 12.2564 0.106814 12.0881C0.0363282 11.9198 0.000426334 11.7394 0.00118004 11.5574C0.00193374 11.3754 0.0393279 11.1953 0.111206 11.0275C0.183083 10.8598 0.288022 10.7077 0.419969 10.58L3.64268 6.96018L0.418801 3.33805C0.286854 3.21039 0.181915 3.05829 0.110037 2.89054C0.0381595 2.72279 0.000765327 2.54271 1.16253e-05 2.36067C-0.000742076 2.17864 0.0351598 1.99826 0.105646 1.82994C0.176132 1.66162 0.279808 1.50869 0.410693 1.37997C0.541578 1.25125 0.697083 1.14929 0.868236 1.07997C1.03939 1.01065 1.22281 0.975344 1.4079 0.976086C1.593 0.976827 1.77612 1.0136 1.94669 1.08429C2.11727 1.15498 2.27192 1.25818 2.40174 1.38794L5.50058 4.87102L8.59826 1.38794C8.72808 1.25818 8.88273 1.15498 9.05331 1.08429C9.22388 1.0136 9.407 0.976827 9.5921 0.976086C9.77719 0.975344 9.96061 1.01065 10.1318 1.07997C10.3029 1.14929 10.4584 1.25125 10.5893 1.37997C10.7202 1.50869 10.8239 1.66162 10.8944 1.82994C10.9648 1.99826 11.0007 2.17864 11 2.36067C10.9992 2.54271 10.9618 2.72279 10.89 2.89054C10.8181 3.05829 10.7131 3.21039 10.5812 3.33805L7.35849 6.96018L10.5812 10.58C10.7116 10.7081 10.815 10.8602 10.8856 11.0276C10.9561 11.195 10.9924 11.3744 10.9924 11.5556C10.9924 11.7369 10.9561 11.9163 10.8856 12.0837C10.815 12.2511 10.7116 12.4032 10.5812 12.5313Z"
                    fill="#F28252"
                    fill-opacity="0.85"
                  />
                </svg>
              </button>
            </div> */}
                {/* <div className="text-center lg:text-right">
              <button
                disabled
                className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark disabled:bg-primary-light text-primary-white text-mobile-h5 lg:text-h5"
              >
                Confirm
              </button>
            </div> */}
                <button
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      uploadUserViaExcel: false,
                    }));
                    setMiniLoader(false);
                  }}
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
          {modals.successMsg && (
            <DialogBox show={modals.successMsg}>
              <PopUp
                button="Back to Users List"
                title="New User Created Successfully."
                main="A New User has been added to this organisation"
                body="The newly added user will be informed via email on their registered email address. Please instruct them to reset password prior to logging on the ePrakashak eBook Reader."
                onClose={() =>
                  setModals((prev) => ({
                    ...prev,
                    successMsg: false,

                  }))
                }
                onClick={() =>
                  setModals((prev) => ({
                    ...prev,
                    successMsg: false,
                    addNewUser: false,
                  }))
                }
              />
            </DialogBox>
          )}
          {modals.successMsgXl && (
            <DialogBox show={modals.successMsgXl}>
              <PopUp
                button="Back to Users List"
                title="New Users Created Successfully."
                main="A New Users has been added to this organisation"
                body="The newly added user will be informed via email on their registered email address. Please instruct them to reset password prior to logging on the ePrakashak eBook Reader."
                onClose={() => {
                  setModals((prev) => ({
                    ...prev,
                    successMsgXl: false,

                  }));
                  getalluser()
                }
                }
                onClick={() =>
                  setModals((prev) => ({
                    ...prev,
                    successMsgXl: false,
                    uploadUserViaExcel: false,
                  }))
                }
              />
            </DialogBox>
          )}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </>
      )}
    </Page>
  );
}
