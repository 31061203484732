import { Transition } from "@headlessui/react";

export default function DialogBox({ children, zindex,show, restProps }) {
  return (
    <Transition show={show}>
      <Transition.Child
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          {...restProps}
        >
          <Transition.Child
            enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className=" z-[9999] lg:z-[50] w-screen min-h-screen bg-pop-up fixed top-0 left-0 flex justify-center items-end md:items-center"
            // className={`${zindex ? "z-[999]" : "z-50"} w-screen min-h-screen bg-pop-up fixed top-0 left-0 flex justify-center items-end md:items-center`}
          >
            {children}
          </Transition.Child>
        </div>
      </Transition.Child>
    </Transition>
  );
}
