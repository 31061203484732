import { useEffect, useState } from "react";

export default function Timeout({ start, handleClick }) {
  const [time, setTime] = useState(start);

  function timeout() {
    const timer = setInterval(() => {
      setTime((prevState) => {
        if (prevState <= 0) {
          clearInterval(timer);
          return 0;
        } else {
          return prevState - 1;
        }
      });
    }, 1000);
  }


  useEffect(() => {
    timeout();
  }, []);

  return time === 0 ? (
    <button onClick={() => {
      setTime(60)
      handleClick()
      timeout()
    }} className="text-tertiary-dark " >Resend</button>
  ) : (
    <span>Resend in  0:{time < 10 ? `0${time}` : time}</span>
  );
}
